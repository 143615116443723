import NiceModal from '@ebay/nice-modal-react';
import { QueryClientProvider } from '@tanstack/react-query';
// NB: This is not loaded in production by default.
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';

import sharedQueryClient from 'flatfox_common/api/QueryClient';
import ErrorBoundary from 'flatfox_common/ui/components/ErrorBoundary';
import useModals from 'flatfox_common/ui/hooks/useModals';

import { ModalComponent } from '@types';

type AppContextType = {
  /**
   * Shows a modal. Use with (ModalType, props).
   */
  showModal: (modalType: string, props: unknown, shouldOpenInNewTab?: boolean) => void;
  /**
   * Close the currently displayed modal, if any.
   */
  closeModal: () => void;
};

/**
 * This is kind of the API to the (locally) global app object. It can be accessed
 * through the useApp() hook below.
 */
const AppContext = React.createContext<AppContextType | null>(null);

export function useApp(): AppContextType {
  const context = React.useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within a FlatfoxApp');
  }
  return context;
}

/**
 * Main entry point for Flatfox apps. Does a couple of things:
 * - Initializes localization (numbers, moment timezone)
 * - Mounts and manages all modals
 * - Creates the react-query store and provides the context.
 * - Provides a react error boundary
 * - TBD: Router top level? Incl. a mounting point
 */
function FlatfoxApp({
  mountUrl,
  name,
  modals = [],
  children,
}: {
  mountUrl?: string;
  /** The app's name. This is only used for error reporting to sentry. */
  name: string;
  /** Array of modal components to be used in the app. */
  modals?: ModalComponent<unknown>[];
  children: React.ReactNode;
}) {
  return (
    <FlatfoxBareApp name={name}>
      <BrowserRouter basename={mountUrl}>
        <RoutedApp modals={modals}>{children}</RoutedApp>
      </BrowserRouter>
    </FlatfoxBareApp>
  );
}
export default FlatfoxApp;

/**
 * A slimmed down variant of <FlatfoxApp> without routing & modal support for standalone components.
 */
export function FlatfoxBareApp({
  name,
  children,
}: {
  name: string;
  children: React.ReactNode;
}) {
  const queryClient = sharedQueryClient();

  return (
    <ErrorBoundary context={name}>
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

function RoutedApp({
  modals,
  children,
}: {
  modals: ModalComponent<unknown>[];
  children: React.ReactNode;
}) {
  // ==========================================
  // Modals
  // ==========================================
  const [showModal, closeModal, modal] = useModals(modals);

  // ==========================================
  // Prepare context
  // ==========================================
  const context = useMemo(
    () => ({
      showModal,
      closeModal,
    }),
    [showModal, closeModal]
  );

  return (
    <AppContext.Provider value={context}>
      <NiceModal.Provider>
        {children}
        {modal}
      </NiceModal.Provider>
    </AppContext.Provider>
  );
}
