/**
 * An error class that matches the basic response shape of an Error response from our
 * API. See also: `utils.django.exceptions.api_exception_handler` in the backend. This
 * can be subclassed to extract more specific metadata for different error types.
 */
export class FlatfoxDetailedApiError extends Error {
  type: string;

  code: string[];

  detail: unknown;

  cause: unknown;

  constructor(type: string, code: string[], detail: unknown, cause: unknown) {
    super(`Flatfox API error: ${type}`);
    this.type = type;
    this.code = code;
    this.detail = detail;
    this.cause = cause;
  }
}

export class FlatfoxApiValidationError extends FlatfoxDetailedApiError {
  type = 'ValidationError';

  detail: string[];

  constructor(code: string[], detail: string[], cause: unknown) {
    super('ValidationError', code, detail, cause);
    this.detail = detail;
  }
}

export class FlatfoxSimpleApiError extends Error {
  cause: unknown;

  constructor(message: string, cause: unknown) {
    super(message);
    this.cause = cause;
  }
}
