/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * Flatfox API
 * For customers adding integrations with our API please read the API Reference linked below first.
 * OpenAPI spec version: 0.0.0 (v1)
 */
import { useMutation } from '@tanstack/react-query';
import type { MutationFunction, UseMutationOptions } from '@tanstack/react-query';

import httpWrapper from '../../../../flatfox_common/httpWrapper';
import type { ThreadView } from './v1.schemas';

/**
 * Registers a view on something and returns additional data based
on the view.
 */
export const viewCreateManyCreate = (threadView: ThreadView[]) =>
  httpWrapper<void>({
    url: `/api/v1/view/create-many/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: threadView,
  });

export const getViewCreateManyCreateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof viewCreateManyCreate>>,
    TError,
    { data: ThreadView[] },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof viewCreateManyCreate>>,
  TError,
  { data: ThreadView[] },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof viewCreateManyCreate>>,
    { data: ThreadView[] }
  > = (props) => {
    const { data } = props ?? {};

    return viewCreateManyCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ViewCreateManyCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof viewCreateManyCreate>>
>;
export type ViewCreateManyCreateMutationBody = ThreadView[];
export type ViewCreateManyCreateMutationError = unknown;

export const useViewCreateManyCreate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof viewCreateManyCreate>>,
    TError,
    { data: ThreadView[] },
    TContext
  >;
}) => {
  const mutationOptions = getViewCreateManyCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
