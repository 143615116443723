import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { genericError } from 'flatfox_common/ui/text/error';

function Alert({ kind, children, className }) {
  const finalClassName = classNames('fui-alert', className, {
    [`fui-alert--${kind}`]: kind,
  });

  return <div className={finalClassName}>{children}</div>;
}
export default Alert;
Alert.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  kind: PropTypes.oneOf(['error', 'info', 'warning', 'success']),
};

Alert.defaultProps = {
  kind: 'info',
};

function GenericError({ isNotified }: { isNotified?: boolean }) {
  return <Alert kind='error'>{genericError(isNotified)}</Alert>;
}

Alert.GenericError = GenericError;
