import mapValues from 'lodash/mapValues';

/**
 * Each detector must be a function that returns a boolean, with `true`
 * indicating that the corresponding feature is supported in the current browser.
 */
const detectors = {
  /**
   * Whether Resize Observer API is available.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Resize_Observer_API}
   */
  resizeObserver: () => typeof ResizeObserver !== 'undefined',
  /**
   * Whether Pointer Events are available.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Pointer_events}
   */
  pointerEvents: () => typeof PointerEvent !== 'undefined',
  /**
   * Whether `crypto.subtle` is available.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto}
   */
  cryptoSubtle: () => typeof crypto !== 'undefined' && !!crypto.subtle,
  /**
   * Whether `MediaQueryList` is an `EventTarget`.
   * If not, we need to use the deprecated `addListener()` and `removeListener()` methods as fallback.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList#events}
   */
  mediaQueryListIsEventTarget: () => {
    const mql = matchMedia('screen');
    return typeof mql.addEventListener === 'function';
  },
} as const;

type FeatureName = keyof typeof detectors;

const browserFeatures: Record<FeatureName, boolean> = mapValues(detectors, (detector) =>
  detector()
);

export default function getSupportedBrowserFeatures(): Record<FeatureName, boolean> {
  return browserFeatures;
}
