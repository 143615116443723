/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * Flatfox API
 * For customers adding integrations with our API please read the API Reference linked below first.
 * OpenAPI spec version: 0.0.0 (v1)
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import httpWrapper from '../../../../flatfox_common/httpWrapper';
import type { Contact, ContactListParams, PaginatedContactList } from './v1.schemas';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
  T
>() => T extends Y ? 1 : 2
  ? A
  : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object
        ? NonReadonly<NonNullable<T[P]>>
        : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

export const contactList = (params?: ContactListParams, signal?: AbortSignal) =>
  httpWrapper<PaginatedContactList>({
    url: `/api/v1/contact/`,
    method: 'GET',
    params,
    signal,
  });

export const getContactListQueryKey = (params?: ContactListParams) =>
  [`/api/v1/contact/`, ...(params ? [params] : [])] as const;

export const getContactListQueryOptions = <
  TData = Awaited<ReturnType<typeof contactList>>,
  TError = unknown
>(
  params?: ContactListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof contactList>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getContactListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof contactList>>> = ({ signal }) =>
    contactList(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof contactList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ContactListQueryResult = NonNullable<Awaited<ReturnType<typeof contactList>>>;
export type ContactListQueryError = unknown;

export const useContactList = <
  TData = Awaited<ReturnType<typeof contactList>>,
  TError = unknown
>(
  params?: ContactListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof contactList>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getContactListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Creating/updating contacts being imported from a CSV file uploaded by admin
 */
export const contactSyncCreate = (contact: NonReadonly<Contact[]>) =>
  httpWrapper<Contact>({
    url: `/api/v1/contact/sync/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: contact,
  });

export const getContactSyncCreateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof contactSyncCreate>>,
    TError,
    { data: NonReadonly<Contact[]> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof contactSyncCreate>>,
  TError,
  { data: NonReadonly<Contact[]> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof contactSyncCreate>>,
    { data: NonReadonly<Contact[]> }
  > = (props) => {
    const { data } = props ?? {};

    return contactSyncCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ContactSyncCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof contactSyncCreate>>
>;
export type ContactSyncCreateMutationBody = NonReadonly<Contact[]>;
export type ContactSyncCreateMutationError = unknown;

export const useContactSyncCreate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof contactSyncCreate>>,
    TError,
    { data: NonReadonly<Contact[]> },
    TContext
  >;
}) => {
  const mutationOptions = getContactSyncCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
