import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

function renderPrivateLiability(
  node: ReactDOM.Container,
  props: ComponentProps<typeof PrivateLiabilityApp>
) {
  const PrivateLiabilityApp = createLazyComponent(() => import('./PrivateLiabilityApp'));
  attachRoot(<PrivateLiabilityApp {...props} />, node);
}

function setupInfoModals(selector: string) {
  const InfoModalButtonApp = createLazyComponent(() => import('./InfoModalButton'));

  const infoModalNodes = document.getElementsByClassName(selector);
  for (let i = 0; i < infoModalNodes.length; i += 1) {
    const trigger = infoModalNodes[i];
    const triggerId = trigger.getAttribute('id');

    if (!triggerId) {
      console.error('data-modal must have an ID');
      break;
    }
    const container = document.querySelector(`[aria-labelledby=${triggerId}]`);
    const content = container.querySelector('article');
    const header = container.querySelector('header');

    attachRoot(
      <InfoModalButtonApp title={header.innerHTML} content={content.innerHTML} />,
      infoModalNodes[i]
    );
  }
}

window.flatfox.insurance = {
  setupInfoModals,
  renderPrivateLiability,
};
