import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import includes from 'lodash/includes';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';

import * as apiUrls from 'flatfox_common/api/apiUrls';
import * as config from 'flatfox_common/config';
import * as http from 'flatfox_common/http';

import { addSignalListener } from 'flatfox_website/scripts/signals';

/**
 * Easiest way to communicate with native apps is over own urls.
 * To achieve this, we create a temporary iframe, call the specific url and
 * remove it again.
 */
function createAndRemoveIFrame(url) {
  const iFrame = document.createElement('IFRAME');
  iFrame.setAttribute('src', url);
  document.body.appendChild(iFrame);
  iFrame.parentNode.removeChild(iFrame);
}

/**
 * Sends a specific signal to the Flatfox mobile app, to pass information from
 * a webview to the native app part. A signal consists of a string name and
 * zero or more arguments. For details, see:
 * https://www.notion.so/smgnet/Web-View-Integration-0359a0c5b6e740dd965c88052a13947c
 */
function sendSignalToMobileApp(signal, ...args) {
  if (args.length > 0) {
    const encodedArgs = args.map((arg) => encodeURIComponent(arg));
    createAndRemoveIFrame(`flatfox://${signal}/${encodedArgs.join('/')}`);
  } else {
    createAndRemoveIFrame(`flatfox://${signal}`);
  }
}

/**
 * Installs the listener for the unreads changed event (an internal eventemitter
 * based event). It will then poll the server for the latest count and update
 * the IOS App accordingly.
 */
function setupAppUnreadListener() {
  function updateApplicationCallout(count) {
    sendSignalToMobileApp('setApplicationsBadgeCount', count);
  }

  function updateAdvertiserCallout(count) {
    sendSignalToMobileApp('setAdsBadgeCount', count);
  }

  /**
   * Gets the unread counts from the API and updates the DOM.
   */
  function updateUnreadCounts() {
    const userPk = config.getUserPk();

    // We might be logged out, in which case we don't do anything.
    if (!userPk) {
      return;
    }

    const urlTemplate = apiUrls.messenger.unreadStates;
    const urlAction = 'groupby/role/';
    http.get(apiUrls.list(urlTemplate + urlAction, { participant: userPk })).then(
      (unreads) => {
        // Convert the list of unreads to a { role => count } mapping.
        let counts = keyBy(unreads, 'role');
        counts = mapValues(counts, (unread) => unread.count);
        updateApplicationCallout(counts.applicant || 0);
        updateAdvertiserCallout(counts.advertiser || 0);
      },
      (error) => {
        const code = get(error, ['cause', 'error', 'code']);
        if (code && includes(code, 'not_authenticated')) {
          // We'll swallow PermissionDenied here, as it might just happen the user
          // has been logged out in the meantime.
          return;
        }

        if (error && error.name === 'TypeError' && error.message === 'Failed to fetch') {
          // After updating to the latest sentry sdk version, we ended up having
          // close to a 100k events, almost exclusively originating from android
          // webviews. Likely this is just some browser-specific behavior, that
          // does not affect us too much. Also note that the backend only gets
          // 1/3 of the errors reported by the frontend, hence 2/3 originate
          // from somewhere in the browser or network. (Cloudflare and Nginx
          // report the same number of 499s on the /unread api)
          return;
        }

        // This is something we might want to know about as it would hint to a
        // general problem.
        Sentry.withScope((scope) => {
          scope.setExtra('error', error);
          Sentry.captureMessage('FOX-39008: Error when reading unreads from API');
        });
      }
    );
  }

  addSignalListener('unreadCountsChanged', updateUnreadCounts);

  // Call immediately
  updateUnreadCounts();
}

function setupMobileAppSignalSending() {
  addSignalListener('userRequestLogout', () => {
    sendSignalToMobileApp('userRequestLogout');
  });
  addSignalListener('userRequestRemoveAccount', () => {
    sendSignalToMobileApp('userRequestRemoveAccount');
  });
  addSignalListener('goBack', () => {
    sendSignalToMobileApp('goBack');
  });
  addSignalListener('continue', () => {
    sendSignalToMobileApp('continue');
  });
  addSignalListener('purchaseComplete', (purchaseToken) => {
    sendSignalToMobileApp('purchaseComplete', purchaseToken);
  });
}

export default function setupApp() {
  setupAppUnreadListener();
  setupMobileAppSignalSending();
}
