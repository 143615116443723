import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

function runAttendanceEditor(
  node: ReactDOM.Container,
  props: ComponentProps<typeof AttendanceEditorApp>
) {
  const AttendanceEditorApp = createLazyComponent(
    () => import('./attendance/AttendanceEditorApp')
  );
  attachRoot(<AttendanceEditorApp {...props} />, node);
}

window.flatfox.calendar = {
  runAttendanceEditor,
};
