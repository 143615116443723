import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

export function render(
  node: ReactDOM.Container,
  props: ComponentProps<typeof SearchApp>
) {
  const SearchApp = createLazyComponent(() => import('./SearchApp'));
  attachRoot(<SearchApp {...props} />, node);
}

function renderQuickSearchBar(selector: string) {
  const nodes = document.querySelectorAll(selector);
  if (nodes.length === 0) {
    return;
  }

  // This doesn't use react, but will include all the mapbox stuff, hence
  // let's keep it in a separate bundle.
  import('./QuickSearchBar').then(({ setupQuickSearchAutocomplete }) => {
    for (let i = 0; i < nodes.length; i += 1) {
      setupQuickSearchAutocomplete(nodes[i]);
    }
  });
}

window.flatfox.search = { render, renderQuickSearchBar };
