import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

function setup(node: ReactDOM.Container, props: ComponentProps<typeof ListingsApp>) {
  const ListingsApp = createLazyComponent(() => import('./ListingsApp'));
  attachRoot(<ListingsApp {...props} />, node);
}

window.flatfox.listings = { setup };
