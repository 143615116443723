import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

function conversationApp(
  node: ReactDOM.Container,
  props: ComponentProps<typeof ConversationApp>
) {
  const ConversationApp = createLazyComponent(
    () => import('./conversation_app/ConversationApp')
  );

  attachRoot(<ConversationApp {...props} />, node);
}

window.flatfox.messenger = { conversationApp };
