import { isAuthenticated } from 'flatfox_common/config';
import { getLoginUrl } from 'flatfox_common/webUrls';

/**
 * Redirects users to the login page. After logging in, they are redirected
 * back to where they were.
 */
export function redirectUnauthenticatedToLogin() {
  if (isAuthenticated()) return;

  window.location.href = `${getLoginUrl()}?next=${window.location.pathname}${
    window.location.search
  }`;
}
