/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * Flatfox API
 * For customers adding integrations with our API please read the API Reference linked below first.
 * OpenAPI spec version: 0.0.0 (v1)
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import httpWrapper from '../../../../flatfox_common/httpWrapper';
import type {
  ListingListParams,
  ListingRetrieveParams,
  MyFlat,
  PaginatedMyFlatList,
} from './v1.schemas';

/**
 * Allows the view to prefetch data, given the queryset that will be displayed,
and add it to the actual instances in one batch. For speed optimization.
 */
export const listingList = (params?: ListingListParams, signal?: AbortSignal) =>
  httpWrapper<PaginatedMyFlatList>({
    url: `/api/v1/listing/`,
    method: 'GET',
    params,
    signal,
  });

export const getListingListQueryKey = (params?: ListingListParams) =>
  [`/api/v1/listing/`, ...(params ? [params] : [])] as const;

export const getListingListQueryOptions = <
  TData = Awaited<ReturnType<typeof listingList>>,
  TError = unknown
>(
  params?: ListingListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listingList>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListingListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listingList>>> = ({ signal }) =>
    listingList(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listingList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListingListQueryResult = NonNullable<Awaited<ReturnType<typeof listingList>>>;
export type ListingListQueryError = unknown;

export const useListingList = <
  TData = Awaited<ReturnType<typeof listingList>>,
  TError = unknown
>(
  params?: ListingListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listingList>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListingListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Some providers like Swisscom do not support HTTP PATCH requests. This depends
on the firewall configuration of the respective organization. However, it
is difficult to have this corrected by the provider, so we use an HTTP PUT,
which is interpreted as a PATCH.

If this mixin is used, the `ResourceObject.patchAsPut` must be used on the
client side.
 */
export const listingRetrieve = (
  id: string,
  params?: ListingRetrieveParams,
  signal?: AbortSignal
) =>
  httpWrapper<MyFlat>({ url: `/api/v1/listing/${id}/`, method: 'GET', params, signal });

export const getListingRetrieveQueryKey = (id: string, params?: ListingRetrieveParams) =>
  [`/api/v1/listing/${id}/`, ...(params ? [params] : [])] as const;

export const getListingRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof listingRetrieve>>,
  TError = unknown
>(
  id: string,
  params?: ListingRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listingRetrieve>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListingRetrieveQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listingRetrieve>>> = ({
    signal,
  }) => listingRetrieve(id, params, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listingRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListingRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof listingRetrieve>>
>;
export type ListingRetrieveQueryError = unknown;

export const useListingRetrieve = <
  TData = Awaited<ReturnType<typeof listingRetrieve>>,
  TError = unknown
>(
  id: string,
  params?: ListingRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listingRetrieve>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListingRetrieveQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
