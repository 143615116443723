import cookies from 'cookies-js';

export default function setupCsrfMiddlewareToken() {
  /*
  This will change the hidden csrfmiddlewaretoken value to match the cookie value
  on every django form submit.
  Why? To prevent REASON_BAD_TOKEN - CSRF token missing or incorrect.
  It happens if user opens 2 tabs, logs in within first tab(new csrf token is generated)
  withot refreshing the second one, therefore second tab remains with old tokens.
  Submitting the form on the second tab will fail with REASON_BAD_TOKEN.
  It's a known django issue.
  */
  const inputs = document.getElementsByName('csrfmiddlewaretoken');
  Array.from(inputs).forEach((input) => {
    input.form.addEventListener('submit', (ev) => {
      // If there is no cookie, keep the old value.
      input.value = cookies.get('csrftoken') || input.value;
    });
  });
}
