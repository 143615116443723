import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

function setupWidget(
  node: ReactDOM.Container,
  props: ComponentProps<typeof QuicklineServicesWizard>
) {
  const FlatfoxApp = createLazyComponent(
    () => import('flatfox_common/ui/components/FlatfoxApp')
  );
  const QuicklineServicesWizard = createLazyComponent(
    () => import('./components/QuicklineServicesWizard')
  );

  attachRoot(
    <FlatfoxApp name='QuicklineServicesWizard'>
      <QuicklineServicesWizard {...props} />
    </FlatfoxApp>,
    node
  );
}

window.flatfox.quickline_services = { setupWidget };
