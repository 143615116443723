/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * Flatfox API
 * For customers adding integrations with our API please read the API Reference linked below first.
 * OpenAPI spec version: 0.0.0 (v1)
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import httpWrapper from '../../../../flatfox_common/httpWrapper';
import type {
  GroupedUnreadState,
  PaginatedUnreadStateList,
  UnreadStateGroupbyListParams,
  UnreadStateListParams,
} from './v1.schemas';

/**
 * Allows the view to prefetch data, given the queryset that will be displayed,
and add it to the actual instances in one batch. For speed optimization.
 */
export const unreadStateList = (params?: UnreadStateListParams, signal?: AbortSignal) =>
  httpWrapper<PaginatedUnreadStateList>({
    url: `/api/v1/unread-state/`,
    method: 'GET',
    params,
    signal,
  });

export const getUnreadStateListQueryKey = (params?: UnreadStateListParams) =>
  [`/api/v1/unread-state/`, ...(params ? [params] : [])] as const;

export const getUnreadStateListQueryOptions = <
  TData = Awaited<ReturnType<typeof unreadStateList>>,
  TError = unknown
>(
  params?: UnreadStateListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof unreadStateList>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUnreadStateListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof unreadStateList>>> = ({
    signal,
  }) => unreadStateList(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof unreadStateList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UnreadStateListQueryResult = NonNullable<
  Awaited<ReturnType<typeof unreadStateList>>
>;
export type UnreadStateListQueryError = unknown;

export const useUnreadStateList = <
  TData = Awaited<ReturnType<typeof unreadStateList>>,
  TError = unknown
>(
  params?: UnreadStateListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof unreadStateList>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUnreadStateListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Grouping the unreads is very useful for various clients, so they can
reduce the amount of data to transmit or the complexity of computation.
Since unreads are regulary polled, this is worth it.
 */
export const unreadStateGroupbyList = (
  groupbyFields: string,
  params?: UnreadStateGroupbyListParams,
  signal?: AbortSignal
) =>
  httpWrapper<GroupedUnreadState[]>({
    url: `/api/v1/unread-state/groupby/${groupbyFields}/`,
    method: 'GET',
    params,
    signal,
  });

export const getUnreadStateGroupbyListQueryKey = (
  groupbyFields: string,
  params?: UnreadStateGroupbyListParams
) =>
  [
    `/api/v1/unread-state/groupby/${groupbyFields}/`,
    ...(params ? [params] : []),
  ] as const;

export const getUnreadStateGroupbyListQueryOptions = <
  TData = Awaited<ReturnType<typeof unreadStateGroupbyList>>,
  TError = unknown
>(
  groupbyFields: string,
  params?: UnreadStateGroupbyListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof unreadStateGroupbyList>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUnreadStateGroupbyListQueryKey(groupbyFields, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof unreadStateGroupbyList>>> = ({
    signal,
  }) => unreadStateGroupbyList(groupbyFields, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!groupbyFields,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof unreadStateGroupbyList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UnreadStateGroupbyListQueryResult = NonNullable<
  Awaited<ReturnType<typeof unreadStateGroupbyList>>
>;
export type UnreadStateGroupbyListQueryError = unknown;

export const useUnreadStateGroupbyList = <
  TData = Awaited<ReturnType<typeof unreadStateGroupbyList>>,
  TError = unknown
>(
  groupbyFields: string,
  params?: UnreadStateGroupbyListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof unreadStateGroupbyList>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUnreadStateGroupbyListQueryOptions(
    groupbyFields,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
