/**
 * Adds a confirm modal for all onClick handlers on nodes with data-confirm attribute.
 * The confirm message will be the content of that data attribute.
 */
export default function setupConfirm() {
  const nodes = document.querySelectorAll('[data-fui-confirm]');
  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];
    const askText = node.getAttribute('data-fui-confirm');
    node.addEventListener('click', (e) => {
      // eslint-disable-next-line
      const res = confirm(askText);
      if (!res) {
        e.stopPropagation();
        e.preventDefault();
      }
    });
  }
}
