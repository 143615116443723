import { addClass } from 'flatfox_common/ui/utils/dom';

const AUTOCLOSE_TIME = 8000;

function animate(node, animClass, duration) {
  addClass(node, animClass);
  setTimeout(() => {
    addClass(node, `${animClass}-active`);
  }, duration);
}

function animateLeave(node) {
  animate(node, 'ff-messages--leave', 200);
}

export default function setupMessages() {
  const messages = document.querySelector('.ff-messages');
  if (messages) {
    // Make it autoclose if requested
    setTimeout(() => animateLeave(messages), AUTOCLOSE_TIME);
  }
}
