import EventEmitter from 'eventemitter3';

/**
 * Available signal names and what arguments they take, if any.
 *
 * Method names are mapped as available signal names.
 * If a signal has an argument list, it is used to both check `emit()` calls,
 * and to determine the argument list of the event handler.
 */
type FlatfoxSignalMap = {
  continue(): void;
  goBack(): void;
  purchaseComplete(token: string): void;
  unreadCountsChanged(): void;

  // Webview-specific signals
  userRequestLogout(): void;
  userRequestRemoveAccount(): void;
};

// TODO: Switch to `EventTarget` and drop `eventemitter3` dependency once our browser requirements allows us to do so:
//       https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/EventTarget
const signals = new EventEmitter<FlatfoxSignalMap>();

export type SignalName = EventEmitter.EventNames<FlatfoxSignalMap>;
export type SignalListener<T extends SignalName> = EventEmitter.EventListener<
  FlatfoxSignalMap,
  T
>;
export type SignalArgs<T extends SignalName> = EventEmitter.EventArgs<
  FlatfoxSignalMap,
  T
>;

type ListenerCleanup = () => void;

/**
 * Emit the given signal. Consult `FlatfoxSignalMap` for available signals and their arguments.
 */
export function emitSignal<T extends SignalName>(
  signalName: T,
  ...args: [...SignalArgs<T>]
) {
  signals.emit(signalName, ...args);
}

/**
 * Add the given listener to the given signal. Consult `FlatfoxSignalMap` for available signals and their arguments.
 *
 * To remove the listner again, call the returned cleanup function.
 */
export function addSignalListener<T extends SignalName>(
  signalName: T,
  listener: SignalListener<T>
): ListenerCleanup {
  signals.addListener(signalName, listener);

  return () => signals.removeListener(signalName, listener);
}

/**
 * Clear all signal listeners. Should only be used for tests.
 */
export function clearSignalListeners() {
  signals.removeAllListeners();
}
