// Adds a spinner element to given button (as well as button[type=submit]).
// Doesn't work for input[type=submit] elements, though, as we need inner
// html for this to work.
export function addSpinner(button) {
  if (button.querySelectorAll('.spinner').length === 0) {
    const spinner = document.createElement('span');
    const space = document.createTextNode('\u00A0');
    const child = button.childNodes[0];
    spinner.className = 'spinner small';
    button.insertBefore(spinner, child);
    button.insertBefore(space, child);
  }
}

/**
 * Removes the spinner from a button.
 */
export function removeSpinner(button) {
  const spinner = button.querySelectorAll('.spinner');
  if (spinner && spinner.parentNode) {
    spinner.parentNode.removeChild(spinner);
  }
}

/**
 * Disables given elements, excluding excludeElement.
 */
function disableExcept(elements, excludeElement) {
  for (let i = 0; i < elements.length; i += 1) {
    const element = elements[i];
    if (element !== excludeElement) {
      // eslint-disable-next-line no-param-reassign
      elements[i].disabled = true;
    }
  }
}

/**
 * Enables given elements.
 */
function enable(elements) {
  for (let i = 0; i < elements.length; i += 1) {
    // eslint-disable-next-line no-param-reassign
    elements[i].disabled = false;
  }
}

/**
 * Prevents double submission of a form by locking all buttons on the first
 * submit. Also adds a spinner to the button that triggered the submit.
 */
function lockOnSubmit(formElement) {
  let clickedButton;

  function onClick(e) {
    clickedButton = e.target;
  }

  // Track clicks on all submit buttons
  const buttons = formElement.querySelectorAll('[type=submit]');
  for (let i = 0; i < buttons.length; i += 1) {
    const button = buttons[i];
    button.addEventListener('click', onClick);
  }

  // Prevent form from being submitted twice. Also, lock the submit
  // buttons
  formElement.addEventListener('submit', (e) => {
    const form = e.target;
    if (form.getAttribute('data-submitting')) {
      // Previously submitted - don't submit again
      e.preventDefault();
    } else {
      // Mark it so that the next submit can be ignored
      form.setAttribute('data-submitting', true);

      // Mark the button that made the click with a spinner and prevent
      // it from being pressed again.
      if (clickedButton) {
        addSpinner(clickedButton);
      }

      // Disable all submitButtons except the one that was clicked.
      // NB: if we would disable the clicked button as well, the button
      //     would not be present in the form data.
      const submitButtons = formElement.querySelectorAll('[type=submit]');
      const otherButtons = formElement.querySelectorAll('button[type=button]');
      disableExcept(submitButtons, clickedButton);
      disableExcept(otherButtons, clickedButton);
    }

    // When user navigates back by browser-back-button, page loads from cache (bfcache)
    // if there was no redirect & GET after a POST. Thus, we need to unwind what we
    // just did above before the page enters the cache.
    window.addEventListener('beforeunload', () => {
      enable(buttons);
      if (clickedButton) {
        removeSpinner(clickedButton);
      }
      form.setAttribute('data-submitting', false);
    });
  });
}

/**
 * Just add a data-fui-lock-on-submit to any form to use this.
 */
export default function setupLocks() {
  const nodes = document.querySelectorAll('form[data-fui-lock-on-submit]');
  for (let i = 0; i < nodes.length; i += 1) {
    const form = nodes[i];
    lockOnSubmit(form);
  }
}
