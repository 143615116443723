function setup() {
  import('flatfox_website/views/account/account').then(({ default: setupAccount }) => {
    // no need to configureLocale, because there is nothing localized further
    // down until we hit react
    setupAccount();
  });
}

window.flatfox.account = { setup };

export {};
