import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

import type DossierEditorAppProps from './DossierEditorApp';
import type PreSubmitAppProps from './PreSubmitApp';

function setupEditApp(
  node: ReactDOM.Container,
  props: ComponentProps<typeof DossierEditorAppProps>
) {
  const DossierEditorApp = createLazyComponent(() => import('./DossierEditorApp'));
  attachRoot(<DossierEditorApp {...props} />, node);
}

function setupPreSubmitApp(
  node: ReactDOM.Container,
  props: ComponentProps<typeof PreSubmitAppProps>
) {
  const PreSubmitApp = createLazyComponent(() => import('./PreSubmitApp'));
  attachRoot(<PreSubmitApp {...props} />, node);
}

window.flatfox.myDossier = { setupEditApp, setupPreSubmitApp };
