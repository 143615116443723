import * as http from 'flatfox_common/http';
import { addClass } from 'flatfox_common/ui/utils/dom';

export default function setupGTCNag() {
  const node = document.querySelector('.flatfox-gtc-accept-popup');
  if (!node) {
    return;
  }
  const patchUrl = node.getAttribute('data-url');
  const gtcVersion = node.getAttribute('data-current-gtc-version');
  const submit = node.querySelector('button[type=submit]');

  submit.addEventListener('click', () => {
    http
      .patch(patchUrl, { gtc_version: gtcVersion })
      .then(() => addClass(node, 'flatfox-gtc-accept-popup--hidden'));
  });
}
