/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * Flatfox API
 * For customers adding integrations with our API please read the API Reference linked below first.
 * OpenAPI spec version: 0.0.0 (v1)
 */
import { useMutation } from '@tanstack/react-query';
import type { MutationFunction, UseMutationOptions } from '@tanstack/react-query';

import httpWrapper from '../../../../flatfox_common/httpWrapper';
import type { Broadcast, Ticket } from './v1.schemas';

/**
 * Used by property managers to create tickets broadcasting a message to many
recipients.
 */
export const broadcastCreate = (broadcast: Broadcast) =>
  httpWrapper<Ticket>({
    url: `/api/v1/broadcast/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: broadcast,
  });

export const getBroadcastCreateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof broadcastCreate>>,
    TError,
    { data: Broadcast },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof broadcastCreate>>,
  TError,
  { data: Broadcast },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof broadcastCreate>>,
    { data: Broadcast }
  > = (props) => {
    const { data } = props ?? {};

    return broadcastCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type BroadcastCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof broadcastCreate>>
>;
export type BroadcastCreateMutationBody = Broadcast;
export type BroadcastCreateMutationError = unknown;

export const useBroadcastCreate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof broadcastCreate>>,
    TError,
    { data: Broadcast },
    TContext
  >;
}) => {
  const mutationOptions = getBroadcastCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
