function getFunction(flatfox, name) {
  const parts = name.split('.');
  let cur = flatfox;
  for (let i = 0; i < parts.length; i++) {
    cur = cur[parts[i]];
  }
  if (!cur || !cur.apply) {
    throw new Error(`"${name}" is not a flatfox function`);
  }
  return cur;
}

/**
 * Bootloader setup function.
 *
 * Use bootloader_stub as inline script in the html's head section and then
 * - sometimes later, even async - invoke this function to finish bootloader
 * setup.
 */
export default function setupBootLoader(globals) {
  const bootloader = globals.ffbl;

  // hook up flatfox var if not already present
  const flatfox = globals.flatfox || (globals.flatfox = {}); // eslint-disable-line

  // Add 'callMethod' to ffbl. This way the inline script knows to call the method
  // directly instead of adding to the queue.
  const callMethod = (bootloader.callMethod = function callMethod() {
    const method = arguments[0];

    switch (method) {
      case 'call':
        getFunction(flatfox, arguments[1]).apply(
          this,
          Array.prototype.slice.call(arguments, 2)
        );
        break;

      default:
        console.error(`Unknown method '${method}'`); // eslint-disable-line
        break;
    }
  });

  // Work through the queue.
  const { queue } = bootloader;
  while (queue.length) {
    callMethod.apply(this, queue.shift());
  }
}
