import { TicketListStatus } from '@api/v1';
import queryString from 'query-string';

import { getBrand, getLang, getSmgUbmBaseUrl } from 'flatfox_common/config';
import { formatUrl } from 'flatfox_common/ui/utils';

import { Brand } from './ui/constants';

const brand = getBrand();

// We implement those as functions, even when there are no args at all. Still,
// this helps when we want to load the js translations asynchronously.
export function getTermsUrl() {
  switch (brand) {
    case Brand.HOMEGATE:
      return gettext('https://www.homegate.ch/c/en/about-us/legal-issues/gbc');
    case Brand.IMMOSCOUT24:
      return gettext('https://www.immoscout24.ch/c/en/about-us/gtc');
    default:
      return gettext('/en/gtc/');
  }
}

export function getDataPolicyUrl() {
  switch (brand) {
    case Brand.HOMEGATE:
      return gettext('https://www.homegate.ch/c/en/about-us/legal-issues/privacy-policy');
    case Brand.IMMOSCOUT24:
      return gettext('https://www.immoscout24.ch/c/en/about-us/privacy-policy');
    default:
      return gettext('/en/data-policy/');
  }
}

export function getPostListingUrl() {
  return gettext('/en/post-listing/');
}

export function getLoginUrl() {
  return gettext('/en/accounts/login/');
}

export function getPasswordResetUrl() {
  return gettext('/en/accounts/password/reset/');
}

export function getDashboardUrl() {
  return `/${getLang()}/my/dashboard/`;
}

// Either specify bounds (n/e/s/w) or position (lat/lng), not both.
export function getSearchUrl({
  north,
  east,
  south,
  west,
  lat,
  lng,
  query,
  offerType,
  objectCategory,
} = {}) {
  const baseUrl = `/${getLang()}/search/`;
  const args = {
    north,
    east,
    south,
    west,
    lat,
    lng,
    query,
    offerType,
    objectCategory,
  };
  return `${baseUrl}?${queryString.stringify(args)}`;
}

export function getLeaseTerminationsUrl(leaseTerminationPk: number) {
  return formatUrl(`/${getLang()}/my/lease-termination/:leaseTerminationPk/`, {
    leaseTerminationPk,
  });
}

export function getLeaseTerminationThreadUrl(
  leaseTerminationPk: number,
  threadPk: number
) {
  return formatUrl(
    `/${getLang()}/my/lease-termination/:leaseTerminationPk/thread/:threadPk/`,
    {
      leaseTerminationPk,
      threadPk,
    }
  );
}

export function getMyFlatThreadsUrl(flatPk: number) {
  return formatUrl(`/${getLang()}/my/flat/:flatPk/thread/`, { flatPk });
}

export function getMyFlatThreadUrl(flatPk: number, threadPk: number) {
  const translatedUrl = gettext('/en/my/flat/:flatPk/thread/');
  return formatUrl(`${translatedUrl}:threadPk/`, {
    flatPk,
    threadPk,
  });
}

export function getFavoriteListingAppUrl() {
  return `/${getLang()}/my/favorite-listing/`;
}

export function getTicketThreadUrl(ticketPk: number, threadPk: number) {
  return formatUrl(`/${getLang()}/my/ticket/:ticketPk/thread/:threadPk/`, {
    ticketPk,
    threadPk,
  });
}

export function getTicketsUrl(
  ticketPk?: number,
  queryParams: { status?: TicketListStatus } = {}
) {
  if (ticketPk !== undefined) {
    return formatUrl(`/${getLang()}/my/ticket/:ticketPk/`, { ticketPk });
  }
  return formatUrl(`/${getLang()}/my/ticket/`, {}, queryParams);
}

export function getLeaseTerminationWizardObjectsStepUrl(agencySlug: string) {
  const resetUrl = formatUrl('/:lang/lease-termination/', { lang: getLang() });

  return formatUrl(
    '/:lang/:agencySlug/lease-termination/',
    { lang: getLang(), agencySlug },
    { initialStep: 1, reset: encodeURIComponent(resetUrl) }
  );
}

export function getDossierSubmitUrl(listingPk: number, proxy = undefined) {
  return formatUrl(
    '/:lang/listing/:listingPk/submit/',
    { lang: getLang(), listingPk },
    { proxy: proxy ? 1 : undefined }
  );
}

export function getDirectSubmitUrl(code: string) {
  return formatUrl('/d/:code/', { code });
}

export function getApplicationThreadsUrl() {
  return gettext('/en/my/application/');
}

// export function getApplicationThreadUrl(threadPk) {
//   return formatUrl(gettext('/en/my/application/:threadPk/'), { threadPk });
// }

export function getApplicationSummaryUrl(
  applicationPk: number,
  format: string,
  sections: string[]
) {
  const url = formatUrl(`/${getLang()}/application/:applicationPk/summary/`, {
    applicationPk,
  });
  return `${url}?${queryString.stringify({ format, sections })}`;
}

export function getTicketSummaryUrl(
  ticketPk: number,
  format: string,
  sections: string[]
) {
  const url = formatUrl(`/${getLang()}/ticket/:ticketPk/summary/`, {
    ticketPk,
  });

  return `${url}?${queryString.stringify({ format, sections })}`;
}

export function getCreditCheckOrderConfirmationUrl(purchasePk: number) {
  return formatUrl(gettext('/en/debt-extract/order/:purchasePk/confirmation/'), {
    purchasePk,
  });
}

export function getPrivateLiabilityInsuranceUrl() {
  return gettext('/en/private-liability-insurance/');
}

export function getPrivateLiabilityInsuranceSuccessUrl(orderPk: number) {
  return formatUrl(gettext('/en/private-liability-insurance/order/:orderPk/success/'), {
    orderPk,
  });
}

export function getCrmMatchesUrl(listingPk: number) {
  const url = gettext('/en/crm/matches/');
  if (listingPk) {
    return `${url}?listing=${listingPk}`;
  }
  return url;
}

export function getTransferLogUrl(logPk: number) {
  return formatUrl(`/${getLang()}/accounts/interface/transfer-log/:logPk/`, { logPk });
}

export function getSchedulePDFUrl(schedulePk: number, scheduleEventPk?: number | null) {
  const url = formatUrl(`/${getLang()}/viewing/:schedulePk/pdf/`, { schedulePk });
  if (scheduleEventPk) {
    return `${url}?event=${scheduleEventPk}`;
  }
  return url;
}

export function getScheduleSurveyResultsUrl(schedulePk: number) {
  return formatUrl(`/${getLang()}/viewing/:schedulePk/survey-result/`, { schedulePk });
}

export function getBusinessUrl() {
  return `/${getLang()}/business/`;
}

// CMS url
export function getDigitalTerminationUrl() {
  return `/${getLang()}/digitale-wohnungskuendigung/`;
}

export function getListingsOverviewInUbmUrl() {
  return `${getSmgUbmBaseUrl()}${getLang()}/ubm/`;
}
