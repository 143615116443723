import getSupportedBrowserFeatures from 'flatfox_common/ui/utils/browserFeatures';

const { cryptoSubtle: hasCryptoSubtle } = getSupportedBrowserFeatures();

export async function sha256(data: string | undefined): Promise<string | undefined> {
  if (data === undefined || data === '') {
    return data;
  }
  // check if the browser supports crypto
  if (!hasCryptoSubtle) {
    throw new Error('Encryption is not supported by your browser.');
  }

  const msgBuffer = new TextEncoder().encode(data);

  // create hash
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}
