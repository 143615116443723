import type { ConfigUserProfile } from '@api/v1';
import cookies from 'cookies-js';

import { Brand } from './ui/constants';

export function getConfig() {
  return window.flatfoxConfig;
}

export function getDataSource() {
  return getConfig().dataSource;
}

export function getBrand() {
  return getConfig().brand as Brand;
}

export function getUserPk(): number {
  const { user } = getConfig();
  return !!user && user.pk;
}

export function getUserInfo(): ConfigUserProfile | null {
  return getConfig().user;
}

export function isAuthenticated() {
  return !!getUserInfo();
}

export function getUserEmail(): string | null {
  const userInfo = getUserInfo();
  return userInfo ? userInfo.email : null;
}

export function getOwnerPk(): number | null {
  const userInfo = getUserInfo();
  return userInfo ? userInfo.owner_pk : null;
}

export function isOrg(): boolean {
  const userInfo = getUserInfo();
  return !!userInfo && !!userInfo.organization_pk;
}

export function getFeatures(): string[] {
  const userInfo = getUserInfo();
  return userInfo ? userInfo.features : [];
}

export function getLang(): 'en' | 'de' | 'fr' | 'it' {
  return getConfig().lang;
}

export function getLanguageOptions(): {
  value: 'en' | 'de' | 'fr' | 'it';
  label: string;
}[] {
  return getConfig().languages.map((lang) => ({ value: lang[0], label: lang[1] }));
}

export function phoneNumberVerification() {
  return getConfig().phoneNumberVerification;
}

/**
 * Called when a user has authenticated within an SPA. Sets the user info,
 * such that successive calls to getUser() or isAuthenticated() will return data.
 */
export function setUserAuthenticated(userInfo) {
  window.flatfoxConfig.user = userInfo;
}

export function isWebview() {
  return getConfig().isWebviewRequest;
}

export function isAndroidWebview(): boolean {
  return getConfig().isAndroidWebViewRequest;
}

export function isIosWebview(): boolean {
  return getConfig().isIosWebViewRequest;
}

export function getMobileAppVersion(): string | null {
  return getConfig().mobileAppVersion;
}

export function getMainBaseUrl() {
  return getConfig().mainBaseUrl;
}

export function getCurrentOrigin() {
  return window.location.origin;
}

export function getSmgUbmBaseUrl() {
  return getConfig().smgUbmBaseUrl;
}

export function getCsrfFormData(): { name: string; value: string } {
  return {
    name: 'csrfmiddlewaretoken',
    value: cookies.get('csrftoken') ?? '',
  };
}
