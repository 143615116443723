/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * Flatfox API
 * For customers adding integrations with our API please read the API Reference linked below first.
 * OpenAPI spec version: 0.0.0 (v1)
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import httpWrapper from '../../../../flatfox_common/httpWrapper';
import type {
  PatchedSubscription,
  Subscription,
  SubscriptionListParams,
} from './v1.schemas';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
  T
>() => T extends Y ? 1 : 2
  ? A
  : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object
        ? NonReadonly<NonNullable<T[P]>>
        : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

export const subscriptionList = (params?: SubscriptionListParams, signal?: AbortSignal) =>
  httpWrapper<Subscription[]>({
    url: `/api/v1/subscription/`,
    method: 'GET',
    params,
    signal,
  });

export const getSubscriptionListQueryKey = (params?: SubscriptionListParams) =>
  [`/api/v1/subscription/`, ...(params ? [params] : [])] as const;

export const getSubscriptionListQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionList>>,
  TError = unknown
>(
  params?: SubscriptionListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof subscriptionList>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSubscriptionListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof subscriptionList>>> = ({
    signal,
  }) => subscriptionList(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SubscriptionListQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionList>>
>;
export type SubscriptionListQueryError = unknown;

export const useSubscriptionList = <
  TData = Awaited<ReturnType<typeof subscriptionList>>,
  TError = unknown
>(
  params?: SubscriptionListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof subscriptionList>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSubscriptionListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const subscriptionCreate = (subscription: NonReadonly<Subscription>) =>
  httpWrapper<Subscription>({
    url: `/api/v1/subscription/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: subscription,
  });

export const getSubscriptionCreateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscriptionCreate>>,
    TError,
    { data: NonReadonly<Subscription> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof subscriptionCreate>>,
  TError,
  { data: NonReadonly<Subscription> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscriptionCreate>>,
    { data: NonReadonly<Subscription> }
  > = (props) => {
    const { data } = props ?? {};

    return subscriptionCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubscriptionCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionCreate>>
>;
export type SubscriptionCreateMutationBody = NonReadonly<Subscription>;
export type SubscriptionCreateMutationError = unknown;

export const useSubscriptionCreate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscriptionCreate>>,
    TError,
    { data: NonReadonly<Subscription> },
    TContext
  >;
}) => {
  const mutationOptions = getSubscriptionCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const subscriptionPartialUpdate = (
  id: number,
  patchedSubscription: NonReadonly<PatchedSubscription>
) =>
  httpWrapper<Subscription>({
    url: `/api/v1/subscription/${id}/`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchedSubscription,
  });

export const getSubscriptionPartialUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscriptionPartialUpdate>>,
    TError,
    { id: number; data: NonReadonly<PatchedSubscription> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof subscriptionPartialUpdate>>,
  TError,
  { id: number; data: NonReadonly<PatchedSubscription> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscriptionPartialUpdate>>,
    { id: number; data: NonReadonly<PatchedSubscription> }
  > = (props) => {
    const { id, data } = props ?? {};

    return subscriptionPartialUpdate(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubscriptionPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionPartialUpdate>>
>;
export type SubscriptionPartialUpdateMutationBody = NonReadonly<PatchedSubscription>;
export type SubscriptionPartialUpdateMutationError = unknown;

export const useSubscriptionPartialUpdate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscriptionPartialUpdate>>,
    TError,
    { id: number; data: NonReadonly<PatchedSubscription> },
    TContext
  >;
}) => {
  const mutationOptions = getSubscriptionPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const subscriptionDestroy = (id: number) =>
  httpWrapper<void>({ url: `/api/v1/subscription/${id}/`, method: 'DELETE' });

export const getSubscriptionDestroyMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscriptionDestroy>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof subscriptionDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscriptionDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return subscriptionDestroy(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubscriptionDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionDestroy>>
>;

export type SubscriptionDestroyMutationError = unknown;

export const useSubscriptionDestroy = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscriptionDestroy>>,
    TError,
    { id: number },
    TContext
  >;
}) => {
  const mutationOptions = getSubscriptionDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
