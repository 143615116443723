/**
 * Requests for the given element to be displayed in fullscreen.
 */
export function requestFullscreen(element: Element): Promise<void> {
  return ifFullscreenAvailable(
    () =>
      element.requestFullscreen({
        navigationUI: 'hide',
      }),
    Promise.resolve()
  );
}

/**
 * Exit fullscreen if fullscreen mode is on, do nothing is there's not.
 */
export function exitFullscreen(): Promise<void> {
  return ifFullscreenAvailable(
    () => (isFullscreenActive() ? document.exitFullscreen() : Promise.resolve()),
    Promise.resolve()
  );
}

/**
 * Toggle fullscreen mode.
 *
 * @return Promise indicating whether fullscreen mode is active at the time it's resolved.
 */
export function toggleFullscreen(element: Element): Promise<boolean> {
  return ifFullscreenAvailable(async () => {
    if (isFullscreenActive()) {
      await exitFullscreen();
      return false;
    }

    await requestFullscreen(element);

    // Fullscreen request can potentially fail so check the actual state instead of
    // just assuming that it happened.
    return isFullscreenActive();
  }, Promise.resolve(false));
}

/**
 * Check whether fullscreen mode is active.
 */
export function isFullscreenActive(): boolean {
  return !!document.fullscreenElement;
}

export function ifFullscreenAvailable(fn: () => void): void;
export function ifFullscreenAvailable<R>(fn: () => R, fallbackResult: R): R;
export function ifFullscreenAvailable<R>(fn: () => R, fallbackResult?: R): R | undefined {
  if (document.fullscreenEnabled) {
    return fn();
  }
  return fallbackResult;
}
