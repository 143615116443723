/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * Flatfox API
 * For customers adding integrations with our API please read the API Reference linked below first.
 * OpenAPI spec version: 0.0.0 (v1)
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import httpWrapper from '../../../../flatfox_common/httpWrapper';
import type {
  CrmContact,
  CrmContactDestroyParams,
  CrmContactListParams,
  CrmContactPartialUpdateParams,
  CrmContactRetrieveParams,
  CrmSearchProfileCreateParams,
  CrmSearchProfilePartialUpdateParams,
  PaginatedCrmContactList,
  PatchedCrmContact,
  PatchedSearchProfile,
  SearchProfile,
} from './v1.schemas';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
  T
>() => T extends Y ? 1 : 2
  ? A
  : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object
        ? NonReadonly<NonNullable<T[P]>>
        : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

export const crmContactList = (params?: CrmContactListParams, signal?: AbortSignal) =>
  httpWrapper<PaginatedCrmContactList>({
    url: `/api/v1/crm/contact/`,
    method: 'GET',
    params,
    signal,
  });

export const getCrmContactListQueryKey = (params?: CrmContactListParams) =>
  [`/api/v1/crm/contact/`, ...(params ? [params] : [])] as const;

export const getCrmContactListQueryOptions = <
  TData = Awaited<ReturnType<typeof crmContactList>>,
  TError = unknown
>(
  params?: CrmContactListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof crmContactList>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCrmContactListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof crmContactList>>> = ({
    signal,
  }) => crmContactList(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof crmContactList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CrmContactListQueryResult = NonNullable<
  Awaited<ReturnType<typeof crmContactList>>
>;
export type CrmContactListQueryError = unknown;

export const useCrmContactList = <
  TData = Awaited<ReturnType<typeof crmContactList>>,
  TError = unknown
>(
  params?: CrmContactListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof crmContactList>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCrmContactListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const crmContactRetrieve = (
  id: number,
  params?: CrmContactRetrieveParams,
  signal?: AbortSignal
) =>
  httpWrapper<CrmContact>({
    url: `/api/v1/crm/contact/${id}/`,
    method: 'GET',
    params,
    signal,
  });

export const getCrmContactRetrieveQueryKey = (
  id: number,
  params?: CrmContactRetrieveParams
) => [`/api/v1/crm/contact/${id}/`, ...(params ? [params] : [])] as const;

export const getCrmContactRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof crmContactRetrieve>>,
  TError = unknown
>(
  id: number,
  params?: CrmContactRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof crmContactRetrieve>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCrmContactRetrieveQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof crmContactRetrieve>>> = ({
    signal,
  }) => crmContactRetrieve(id, params, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof crmContactRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CrmContactRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof crmContactRetrieve>>
>;
export type CrmContactRetrieveQueryError = unknown;

export const useCrmContactRetrieve = <
  TData = Awaited<ReturnType<typeof crmContactRetrieve>>,
  TError = unknown
>(
  id: number,
  params?: CrmContactRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof crmContactRetrieve>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCrmContactRetrieveQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const crmContactPartialUpdate = (
  id: number,
  patchedCrmContact: NonReadonly<PatchedCrmContact>,
  params?: CrmContactPartialUpdateParams
) =>
  httpWrapper<CrmContact>({
    url: `/api/v1/crm/contact/${id}/`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchedCrmContact,
    params,
  });

export const getCrmContactPartialUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof crmContactPartialUpdate>>,
    TError,
    {
      id: number;
      data: NonReadonly<PatchedCrmContact>;
      params?: CrmContactPartialUpdateParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof crmContactPartialUpdate>>,
  TError,
  {
    id: number;
    data: NonReadonly<PatchedCrmContact>;
    params?: CrmContactPartialUpdateParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof crmContactPartialUpdate>>,
    {
      id: number;
      data: NonReadonly<PatchedCrmContact>;
      params?: CrmContactPartialUpdateParams;
    }
  > = (props) => {
    const { id, data, params } = props ?? {};

    return crmContactPartialUpdate(id, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CrmContactPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof crmContactPartialUpdate>>
>;
export type CrmContactPartialUpdateMutationBody = NonReadonly<PatchedCrmContact>;
export type CrmContactPartialUpdateMutationError = unknown;

export const useCrmContactPartialUpdate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof crmContactPartialUpdate>>,
    TError,
    {
      id: number;
      data: NonReadonly<PatchedCrmContact>;
      params?: CrmContactPartialUpdateParams;
    },
    TContext
  >;
}) => {
  const mutationOptions = getCrmContactPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const crmContactDestroy = (id: number, params?: CrmContactDestroyParams) =>
  httpWrapper<void>({ url: `/api/v1/crm/contact/${id}/`, method: 'DELETE', params });

export const getCrmContactDestroyMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof crmContactDestroy>>,
    TError,
    { id: number; params?: CrmContactDestroyParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof crmContactDestroy>>,
  TError,
  { id: number; params?: CrmContactDestroyParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof crmContactDestroy>>,
    { id: number; params?: CrmContactDestroyParams }
  > = (props) => {
    const { id, params } = props ?? {};

    return crmContactDestroy(id, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CrmContactDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof crmContactDestroy>>
>;

export type CrmContactDestroyMutationError = unknown;

export const useCrmContactDestroy = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof crmContactDestroy>>,
    TError,
    { id: number; params?: CrmContactDestroyParams },
    TContext
  >;
}) => {
  const mutationOptions = getCrmContactDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const crmSearchProfileCreate = (
  searchProfile: NonReadonly<SearchProfile>,
  params?: CrmSearchProfileCreateParams
) =>
  httpWrapper<SearchProfile>({
    url: `/api/v1/crm/search-profile/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchProfile,
    params,
  });

export const getCrmSearchProfileCreateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof crmSearchProfileCreate>>,
    TError,
    { data: NonReadonly<SearchProfile>; params?: CrmSearchProfileCreateParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof crmSearchProfileCreate>>,
  TError,
  { data: NonReadonly<SearchProfile>; params?: CrmSearchProfileCreateParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof crmSearchProfileCreate>>,
    { data: NonReadonly<SearchProfile>; params?: CrmSearchProfileCreateParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return crmSearchProfileCreate(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CrmSearchProfileCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof crmSearchProfileCreate>>
>;
export type CrmSearchProfileCreateMutationBody = NonReadonly<SearchProfile>;
export type CrmSearchProfileCreateMutationError = unknown;

export const useCrmSearchProfileCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof crmSearchProfileCreate>>,
    TError,
    { data: NonReadonly<SearchProfile>; params?: CrmSearchProfileCreateParams },
    TContext
  >;
}) => {
  const mutationOptions = getCrmSearchProfileCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const crmSearchProfilePartialUpdate = (
  id: number,
  patchedSearchProfile: NonReadonly<PatchedSearchProfile>,
  params?: CrmSearchProfilePartialUpdateParams
) =>
  httpWrapper<SearchProfile>({
    url: `/api/v1/crm/search-profile/${id}/`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchedSearchProfile,
    params,
  });

export const getCrmSearchProfilePartialUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof crmSearchProfilePartialUpdate>>,
    TError,
    {
      id: number;
      data: NonReadonly<PatchedSearchProfile>;
      params?: CrmSearchProfilePartialUpdateParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof crmSearchProfilePartialUpdate>>,
  TError,
  {
    id: number;
    data: NonReadonly<PatchedSearchProfile>;
    params?: CrmSearchProfilePartialUpdateParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof crmSearchProfilePartialUpdate>>,
    {
      id: number;
      data: NonReadonly<PatchedSearchProfile>;
      params?: CrmSearchProfilePartialUpdateParams;
    }
  > = (props) => {
    const { id, data, params } = props ?? {};

    return crmSearchProfilePartialUpdate(id, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CrmSearchProfilePartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof crmSearchProfilePartialUpdate>>
>;
export type CrmSearchProfilePartialUpdateMutationBody = NonReadonly<PatchedSearchProfile>;
export type CrmSearchProfilePartialUpdateMutationError = unknown;

export const useCrmSearchProfilePartialUpdate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof crmSearchProfilePartialUpdate>>,
    TError,
    {
      id: number;
      data: NonReadonly<PatchedSearchProfile>;
      params?: CrmSearchProfilePartialUpdateParams;
    },
    TContext
  >;
}) => {
  const mutationOptions = getCrmSearchProfilePartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
