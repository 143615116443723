/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * Flatfox API
 * For customers adding integrations with our API please read the API Reference linked below first.
 * OpenAPI spec version: 0.0.0 (v1)
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import httpWrapper from '../../../../flatfox_common/httpWrapper';
import type { PaginatedTenancyList, TenancyListParams } from './v1.schemas';

export const tenancyList = (params?: TenancyListParams, signal?: AbortSignal) =>
  httpWrapper<PaginatedTenancyList>({
    url: `/api/v1/tenancy/`,
    method: 'GET',
    params,
    signal,
  });

export const getTenancyListQueryKey = (params?: TenancyListParams) =>
  [`/api/v1/tenancy/`, ...(params ? [params] : [])] as const;

export const getTenancyListQueryOptions = <
  TData = Awaited<ReturnType<typeof tenancyList>>,
  TError = unknown
>(
  params?: TenancyListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof tenancyList>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTenancyListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tenancyList>>> = ({ signal }) =>
    tenancyList(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tenancyList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TenancyListQueryResult = NonNullable<Awaited<ReturnType<typeof tenancyList>>>;
export type TenancyListQueryError = unknown;

export const useTenancyList = <
  TData = Awaited<ReturnType<typeof tenancyList>>,
  TError = unknown
>(
  params?: TenancyListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof tenancyList>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTenancyListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
