import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import queryString from 'query-string';
import React from 'react';

/**
 * Renders children injecting some new props.
 */
export function cloneChildren(children, props) {
  return React.Children.map(children, (child) => {
    if (child) {
      return React.cloneElement(child, props);
    }
    return null;
  });
}

/**
 * Parses the given string to an integer. Return null if the string is
 * undefined or empty.
 */
export function intOrNull(v) {
  if (v) {
    return Math.floor(+v);
  }
  return null;
}

export function isNumeric(n) {
  return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
}

export function isPromise(f) {
  return f && f.then;
}

/**
 * Zero is falsy value. Sometimes we need to make special if statement
 * for zero to pass as "defined" value.
 */
export const isDefinedOrZero = (value) => {
  if (value || value === 0 || value === '0') return true;
  return false;
};

/**
 * Run the given function afterwards. You'll have to pass in the result
 * of the first function. If that returned a promise, hook the function
 * up for then() and catch().
 */
export function runFinally(some, func) {
  if (isPromise(some)) {
    return some.then(
      (res) => {
        func();
        return Promise.resolve(res);
      },
      (error) => {
        func();
        return Promise.reject(error);
      }
    );
  }
  func();
  return undefined;
}

export function numToStringOrEmpty(v) {
  if (isNumeric(v)) {
    return v.toString();
  }
  return '';
}

/**
 * Injects a windowWidth property into a component
 */
export function responsiveComponent(Component) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      };
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
      this.setState({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    };

    render() {
      return (
        <Component
          {...this.props}
          windowWidth={this.state.windowWidth}
          windowHeight={this.state.windowHeight}
        />
      );
    }
  };
}

/**
 * Formats a url with :placeholders using the given object's parameters.
 */
export function formatUrl(
  url: string,
  params: Record<string, string | number>,
  queryparams = {}
) {
  let baseUrl = Object.keys(params).reduce(
    (acc, name) => acc.replace(`:${name}`, `${params[name]}`),
    url
  );

  // We might end up with a double slash here, so remove it.
  baseUrl = baseUrl.replace('//', '/');

  // Append querystring
  return urlWithQuery(baseUrl, queryparams);
}

export function urlWithQuery(url: string, query?: object) {
  if (!query) {
    return url;
  }

  const qs = queryString.stringify(query);
  return qs ? `${url}?${qs}` : url;
}

export function getHost() {
  return `${window.location.protocol}//${window.location.host}`;
}

/**
 * Returns true if this a flatfox internal URL, i.e., starts with a slash.
 */
export function isInternalUrl(url: string) {
  return url.search(/^\/\w/) === 0;
}

export function externalUrl(url: string, browser: boolean) {
  if (window.flatfoxConfig.isWebviewRequest) {
    const cmd = browser ? 'openBrowser' : 'openAttachment';
    return `flatfox://${cmd}/${encodeURIComponent(url)}`;
  }
  return url;
}

/**
 * Like <AttachmentLink>, but programatically.
 * A link which opens in the attachment view in the app. On web, this is just
 * a link, but we use the `download` attribute to tell browsers to download it.
 */
export const downloadAttachmentLink = (href: string, filename?: string) => {
  const finalHref = externalUrl(ensureHost(href), false);

  const anchor = document.createElement('a');
  anchor.setAttribute('href', finalHref);
  anchor.setAttribute('download', filename || '');
  anchor.click();
  anchor.remove();
};

type NavigationOptions = {
  /**
   * Whether the given URL should be opened in a new tab. If set to `true`,
   * make sure that the navigation called within the stack of an event handler,
   * otherwise the popup blocker will trigger.
   */
  shouldOpenInNewTab?: boolean;
};

/**
 * Provides an abstraction over window.location, which a) returns a promise,
 * and b) is mockable.
 */
export function navigateTo(
  url: string,
  { shouldOpenInNewTab = false }: NavigationOptions = {}
) {
  if (shouldOpenInNewTab && url.match(/^https?:/)) {
    window.open(url, '_blank', 'noopener');
  } else if (window.parent !== window) {
    // If we're in an iframe, navigate on the top level.
    window.parent.location.href = url;
  } else {
    window.location.href = url;
  }

  // Set the timeout. In the app, the OS will take over immediately, so there's
  // not much need to spin at all. On Desktop, we want to basically spin forever,
  // until the browser stops the current and loads the new page.
  const timeout = window.flatfoxConfig.isWebviewRequest ? 1000 : 10000;
  // eslint-disable-next-line no-promise-executor-return
  return new Promise<void>((resolve) => setTimeout(resolve, timeout));
}

/**
 * Same as navigateTo on desktop. On mobile, these leave the webviews and open
 * in a separate browser.
 */
export function navigateToBrowser(
  url: string,
  navigationOptions: NavigationOptions = {}
) {
  // Make sure the host is set, otherwise this won't work in the app's webviews.
  return navigateTo(externalUrl(ensureHost(url), true), navigationOptions);
}

/**
 * Adds the host to an url if there isn't one.
 */
export function ensureHost(url: string) {
  // We'll just check if this is an absolute URL (i.e., starting with a slash,
  // excluding protocol relative URLs that start with two slashes).
  if (isInternalUrl(url)) {
    return getHost() + url;
  }
  return url;
}

export function joinIfSet(
  strings: (string | null | undefined)[],
  joinChar: string
): string {
  return strings
    .filter((s) => s)
    .join(joinChar)
    .trim();
}

/**
 * Converts immutableJS data structures to JS. Leaves JS stuff untouched.
 */
export function asJS(maybeImmutableJS) {
  if (maybeImmutableJS == null) {
    return null;
  }
  return maybeImmutableJS.toJS ? maybeImmutableJS.toJS() : maybeImmutableJS;
}

/**
 * Creates CSS classnames for our beloved BEM notation. Like classNames, you
 * can pass in an arbitrary number of arguments. Strings are passed through, but
 * objects will be translated to modifers. Switches may be either bools or
 * strings.
 *
 *  bemModifiers('bla', {'bli': true, 'blu', 'foo'}) => "bla bla--bli bla--blu-foo"
 */
export function bemModifiers(baseClass, ...rest) {
  const csStr = [baseClass];
  const csObj = {};
  rest.forEach((objOrString) => {
    if (typeof objOrString === 'object') {
      const switches = objOrString;
      Object.keys(switches).forEach((switchName) => {
        const switchValue = switches[switchName];
        if (typeof switchValue === 'string') {
          csStr.push(`${baseClass}--${kebabCase(switchName)}-${switchValue}`);
        } else {
          csObj[`${baseClass}--${kebabCase(switchName)}`] = switchValue;
        }
      });
    } else {
      csStr.push(objOrString);
    }
  });
  return classNames(csStr, csObj);
}
