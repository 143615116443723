/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * Flatfox API
 * For customers adding integrations with our API please read the API Reference linked below first.
 * OpenAPI spec version: 0.0.0 (v1)
 */
import { useMutation } from '@tanstack/react-query';
import type { MutationFunction, UseMutationOptions } from '@tanstack/react-query';

import httpWrapper from '../../../../flatfox_common/httpWrapper';
import type { ApplicationFlyerPreview } from './v1.schemas';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
  T
>() => T extends Y ? 1 : 2
  ? A
  : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object
        ? NonReadonly<NonNullable<T[P]>>
        : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

/**
 * Generates a PDF preview of the application flyer of a random listing.
 */
export const applicationFlyerPreviewCreate = (
  applicationFlyerPreview: NonReadonly<ApplicationFlyerPreview>
) =>
  httpWrapper<ApplicationFlyerPreview>({
    url: `/api/v1/application-flyer-preview/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: applicationFlyerPreview,
  });

export const getApplicationFlyerPreviewCreateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof applicationFlyerPreviewCreate>>,
    TError,
    { data: NonReadonly<ApplicationFlyerPreview> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof applicationFlyerPreviewCreate>>,
  TError,
  { data: NonReadonly<ApplicationFlyerPreview> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof applicationFlyerPreviewCreate>>,
    { data: NonReadonly<ApplicationFlyerPreview> }
  > = (props) => {
    const { data } = props ?? {};

    return applicationFlyerPreviewCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApplicationFlyerPreviewCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof applicationFlyerPreviewCreate>>
>;
export type ApplicationFlyerPreviewCreateMutationBody =
  NonReadonly<ApplicationFlyerPreview>;
export type ApplicationFlyerPreviewCreateMutationError = unknown;

export const useApplicationFlyerPreviewCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof applicationFlyerPreviewCreate>>,
    TError,
    { data: NonReadonly<ApplicationFlyerPreview> },
    TContext
  >;
}) => {
  const mutationOptions = getApplicationFlyerPreviewCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
