import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

import type BillingInformationApp from './BillingInformationApp';

function setup(
  node: ReactDOM.Container,
  props: ComponentProps<typeof BillingInformationApp>
) {
  const BillingInformation = createLazyComponent(() => import('./BillingInformationApp'));
  attachRoot(<BillingInformation {...props} />, node);
}

window.flatfox.billing_information = { setup };
