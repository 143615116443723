import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

export default function publicEditor(
  node: ReactDOM.Container,
  props: ComponentProps<typeof TicketEditor>
) {
  const TicketEditor = createLazyComponent(() => import('./ticket_editor/TicketEditor'));
  attachRoot(<TicketEditor {...props} />, node);
}

function agentApp(node: ReactDOM.Container, props: ComponentProps<typeof TicketApp>) {
  const TicketApp = createLazyComponent(() => import('./ticket_app/TicketApp'));
  attachRoot(<TicketApp {...props} />, node);
}

function uploadQuoteOfferApp(
  node: ReactDOM.Container,
  props: ComponentProps<typeof UploadQuoteOfferApp>
) {
  const UploadQuoteOfferApp = createLazyComponent(
    () => import('./upload_quote_offer_app/UploadQuoteOfferApp')
  );
  attachRoot(<UploadQuoteOfferApp {...props} />, node);
}

window.flatfox.tickets = { publicEditor, agentApp, uploadQuoteOfferApp };
