import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

function setupMyFavoriteListingApp(
  node: ReactDOM.Container,
  props: ComponentProps<typeof MyFavoriteListingApp>
) {
  const MyFavoriteListingApp = createLazyComponent(
    () => import('./MyFavoriteListingApp')
  );
  attachRoot(<MyFavoriteListingApp {...props} />, node);
}

function setupPublicApp(
  node: ReactDOM.Container,
  props: ComponentProps<typeof FavoriteListingPublicApp>
) {
  const FavoriteListingPublicApp = createLazyComponent(
    () => import('./FavoriteListingPublicApp')
  );
  attachRoot(<FavoriteListingPublicApp {...props} />, node);
}

window.flatfox.shareable = { setupMyFavoriteListingApp, setupPublicApp };
