/**
 * This is where the new navigation lives.
 */
import React from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';
import { NavbarBootstrapData } from 'flatfox_website/views/navbar/common/types';

window.flatfox.navbar = {
  main(containerSelector: string, bootstrapData: NavbarBootstrapData) {
    const rootElement = document.querySelector(containerSelector);
    if (!rootElement) {
      throw new Error(
        `FOX-58275: Could not mount navbar to ${containerSelector}: No element found.`
      );
    }

    // Navbar is in the main bundle, but wrap it `createLazyComponent` to make sure
    // i18n is ready before trying to render it.
    const NavBar = createLazyComponent(
      () => import('flatfox_website/views/navbar/MainNavbar')
    );

    attachRoot(<NavBar bootstrapData={bootstrapData} />, rootElement);
  },
};
