import { getAcrHeaders } from '@smg-real-estate/acr-injection';
import cookies from 'cookies-js';

import * as config from 'flatfox_common/config';

import {
  FlatfoxApiValidationError,
  FlatfoxDetailedApiError,
  FlatfoxSimpleApiError,
} from './api/FlatfoxApiError';

type FlatfoxApiErrorResponse = {
  error: {
    type: string;
    code: string[];
    trace_id: string;
    data_source: string;
    detail: unknown;
  };
};

function isFlatfoxApiErrorResponse(obj: unknown): obj is FlatfoxApiErrorResponse {
  const candidate = obj as Partial<FlatfoxApiErrorResponse>;
  return !!(candidate?.error?.type && candidate?.error?.trace_id);
}

export function get<ResponseBodyType = unknown>(url: string): Promise<ResponseBodyType> {
  return buildRequest(url, { method: 'GET' })
    .then((req) => fetch(req))
    .then(handleResponse);
}

export function post<RequestBodyType = unknown, ResponseBodyType = unknown>(
  url: string,
  data: RequestBodyType
): Promise<ResponseBodyType> {
  return buildRequest(url, { method: 'POST', body: JSON.stringify(data) })
    .then((req) => fetch(req))
    .then(handleResponse);
}

/**
 * Use this for posting form data (e.g., for files). The body object is expected
 * to be an instance of FormData and will be form encoded (not JSON).
 */
export function postFormData<ResponseBodyType>(
  url: string,
  body: FormData
): Promise<ResponseBodyType> {
  // Don't set content type to application/json, it will be set to
  // multipart/form-data;
  return buildRequest(url, { method: 'POST', body }, { 'Content-Type': null })
    .then((req) => fetch(req))
    .then(handleResponse);
}

export function put<RequestBodyType, ResponseBodyType>(
  url: string,
  data: RequestBodyType
): Promise<ResponseBodyType> {
  return buildRequest(url, { method: 'PUT', body: JSON.stringify(data) })
    .then((req) => fetch(req))
    .then(handleResponse);
}

export function patch<RequestBodyType, ResponseBodyType>(
  url: string,
  data: RequestBodyType
): Promise<ResponseBodyType> {
  return buildRequest(url, { method: 'PATCH', body: JSON.stringify(data) })
    .then((req) => fetch(req))
    .then(handleResponse);
}

export function patchAsPut<RequestBodyType, ResponseBodyType>(
  url: string,
  data: RequestBodyType
): Promise<ResponseBodyType> {
  return buildRequest(url, {
    method: 'PUT',
    body: JSON.stringify({ __patchasput__: true, ...data }),
  })
    .then((req) => fetch(req))
    .then(handleResponse);
}

export function destroy(url: string) {
  return buildRequest(url, { method: 'DELETE' })
    .then((req) => fetch(req))
    .then(handleResponse);
}

/**
 * Generic fetch request builder. Adds language support, the CSRF token,
 * and adds cookies to the transport.
 */
export function buildRequest(
  url: string,
  args: RequestInit,
  headers: Record<string, string | null> = {},
  acrHeaders: Record<string, string | null> = null
) {
  const baseHeaders: Record<string, string> = {
    // Provide CSRF token for django.
    'X-CSRFtoken': cookies.get('csrftoken'),
    // Set language
    'Accept-Language': config.getLang(),
    // Need to pass through the data source for server side event logging
    // (see analytics.logger for the other end of the process)
    'X-Datasource': config.getDataSource(),
    // Organization for the org switcher. NB: Send blank string, not 'null' if
    // it isn't defined, as these must be strings.
    'X-Flatfox-Org-Switcher-Owner': config.getOwnerPk() || '',
    // Make the request JSON
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...headers,
  };

  if (acrHeaders) {
    const finalHeaders = { ...baseHeaders, ...acrHeaders };
    // NB: we can't just set Content-Type to undefined, it actually has to be missing
    // from the dict for multipart/form-data.
    // See https://stackoverflow.com/a/4951094
    if (!finalHeaders['Content-Type']) {
      delete finalHeaders['Content-Type'];
    }

    return new Request(url, {
      credentials: 'include',
      headers: { ...finalHeaders, ...acrHeaders },
      ...args,
    });
  }

  return getAcrHeaders().then((acrHeaders_) => {
    const finalHeaders = { ...baseHeaders, ...acrHeaders_ };
    // NB: we can't just set Content-Type to undefined, it actually has to be missing
    // from the dict for multipart/form-data.
    // See https://stackoverflow.com/a/4951094
    if (!finalHeaders['Content-Type']) {
      delete finalHeaders['Content-Type'];
    }

    return new Request(url, {
      credentials: 'include',
      headers: finalHeaders,
      ...args,
    });
  });
}

/**
 * This guy is from react-refetch. We'll want the exact same behaviour,
 * so we can re-use code.
 * Also, fetch() won't convert responses to actual rejections (... if the request
 * hits the server. If we can't send the request, it will reject though).
 * Clients will want that, so they can use parseDRFErrors() and submitWrapper().
 */
async function handleResponse(response: Response) {
  if (response.headers.get('content-length') === '0' || response.status === 204) {
    return undefined;
  }

  const json = response.json();

  if (response.status >= 200 && response.status < 300) {
    return json;
  }

  const cause = await json;
  if (isFlatfoxApiErrorResponse(cause)) {
    const { error } = cause;
    switch (error.type) {
      case 'ValidationError':
        throw new FlatfoxApiValidationError(error.code, error.detail as string[], cause);
      default:
        throw new FlatfoxDetailedApiError(error.type, error.code, error.detail, cause);
    }
  }

  if ('message' in cause) {
    throw new FlatfoxSimpleApiError(cause.message, cause);
  }

  throw new FlatfoxSimpleApiError('Unknown API error', cause);
}
