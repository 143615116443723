import { QueryClient } from '@tanstack/react-query';
import once from 'lodash/once';

import { invalidateUnreadCounts } from 'client/api/unreadState';

import { addSignalListener } from 'flatfox_website/scripts/signals';

/**
 * Returns the shared singleton instance of QueryClient.
 */
const sharedQueryClient: () => QueryClient = once(() => {
  const queryClient = new QueryClient({
    // We generally don't want to poll when the window isn't visible
    // (i.e., in another tab, or, in web views, the view is hidden)
    // because of the load for both server and client (imagine a user having open like 10 Flatfox tabs...).
    //
    // Hence, refetch when the user switches back to the tab.
    // This is the default, but we leave it here for documentation.
    //
    // defaultOptions: {
    //   queries: { refetchOnWindowFocus: true },
    // },
  });

  addSignalListener('unreadCountsChanged', () => {
    invalidateUnreadCounts(queryClient);
  });

  return queryClient;
});

export default sharedQueryClient;
