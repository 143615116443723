import semver from 'semver';

import * as config from 'flatfox_common/config';

export function isAndroidWebView(versionRequirement?: string): boolean {
  if (!config.isAndroidWebview()) {
    return false;
  }
  if (!versionRequirement) {
    return true;
  }
  return checkAppVersionNumber(versionRequirement);
}

export function isIosWebView(versionRequirement?: string): boolean {
  if (!config.isIosWebview()) {
    return false;
  }
  if (!versionRequirement) {
    return true;
  }
  return checkAppVersionNumber(versionRequirement);
}

export function isMobileAppWithListingWizard(): boolean {
  return isAndroidWebView('>=4.5.0') || isIosWebView('>=4.10.0');
}

export function isMobileAppWithViewingPlanner(): boolean {
  return isAndroidWebView('>=4.13.0');
}

function checkAppVersionNumber(versionRequirement: string): boolean {
  const version = config.getMobileAppVersion();
  if (!version) {
    return false;
  }
  return semver.satisfies(version, versionRequirement);
}
