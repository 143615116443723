/**
 * This is a workaround for when user have a translation plugin activated which switches
 * out DOM nodes imperitvely which React really does not like and crashes.
 * Now if react cannot find a node it will check elsewhere.
 *
 * First flagged as a Flatfox issue here https://github.com/flatfox-ag/flatfox-website/issues/5576
 * This is an issue in React and the fix has been taken from a suggestion from a maintainer
 * of React
 * https://github.com/facebook/react/issues/11538#issuecomment-417504600
 *
 * It can have performance indications so we should only fire it when it is needed e.g when
 * a use has a translator plugin active.
 */

export function preventTranslatorCrashingReact() {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.info('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      // eslint-disable-next-line
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.info(
            'Cannot insert before a reference node from a different parent',
            referenceNode,
            this
          );
        }
        return newNode;
      }
      // eslint-disable-next-line
      return originalInsertBefore.apply(this, arguments);
    };
  }
}
