import find from 'lodash/find';
import { AriaAttributes, AriaRole } from 'react';

export function hasClass(element: Element, className: string): boolean {
  return element.className.search(makeRegex(className)) > -1;
}

export function addClass<T extends Element>(element: T, className: string): T {
  if (!hasClass(element, className)) {
    element.className = `${element.className} ${className}`;
  }
  return element;
}

export function removeClass(element: Element, className: string): void {
  element.className = element.className.replace(makeRegex(className), '').trim();
}

export function toggleClass(element: Element, className: string): void {
  if (hasClass(element, className)) {
    removeClass(element, className);
  } else {
    addClass(element, className);
  }
}

function makeRegex(className: string) {
  return new RegExp(`\\b(${className})\\b`, 'g');
}

/**
 * Accessibility related HTML attributes on elements.
 */
export type AccessibilityAttributes = AriaAttributes & { role?: AriaRole | undefined };

/**
 * Properties we need to determine whether an element is focusable.
 * Any HTMLElement subclass should be compatible with this type.
 */
type FocusCandidate = {
  tabIndex: number;
  tagName: string;
  href?: string;
  type?: string;
  disabled?: boolean;
  matches(selectors: string): boolean;
};

/**
 * Checks whether the given element is an element that can gain focus.
 */
export function isFocusable(element: FocusCandidate): boolean {
  if (element.tabIndex < 0) {
    // Element has a negative tab index, either because it has no intrinsic tab order,
    // or it's been taken out of the tab order by explicitly assigning it a negative number.
    return false;
  }

  if (element.matches('[inert], [inert] *')) {
    // Element can't gain focus due to it being an inert element or located within an inert element.
    return false;
  }

  switch (element.tagName) {
    case 'A':
      return !!element.href;
    case 'INPUT':
      return element.type !== 'hidden' && !element.disabled;
    case 'SELECT':
    case 'TEXTAREA':
    case 'BUTTON':
      return !element.disabled;
    default:
      // For elements made focusable through adding `tabindex`,
      // there is nothing that can make it intrinsically not focusable.
      return true;
  }
}

/**
 * A CSS selector that selects any element that can potentially gain focus.
 */
export const FocusCandidateSelector = 'a,input,select,textarea,button,[tabindex]';

/**
 * Find the first descendant that can be focused in the given element.
 * Returns `undefined` if there are no focusable descendants.
 */
export function findFirstFocusableDescendant(
  element: HTMLElement
): HTMLElement | undefined {
  // We have to use lodash's `find()`, as `querySelectorAll()` returns a `NodeList`.
  // Unlike an array, it does not have a `find()` method.
  return find(
    element.querySelectorAll<HTMLElement>(FocusCandidateSelector),
    (candidate) => isFocusable(candidate)
  );
}

/**
 * Check whether the given focus event indicate that the focus has left the element the event listener is attached to.
 * A return value of `undefined` indicates that it cannot be determined where the focus went from the event
 * (e.g., it's not a `blur` event, or `currentTarget` or `relatedTarget` does not contain a DOM node).
 */
export function hasFocusLeftCurrentTarget(
  e: FocusEvent | React.FocusEvent
): boolean | undefined {
  if (!(e.type === 'focusout' || e.type === 'blur')) {
    // Not a blur event, bailing out
    return undefined;
  }

  if (!(e.currentTarget instanceof Element) || !(e.relatedTarget instanceof Node)) {
    // We have no idea how to deal with those targets…
    return undefined;
  }

  return !e.currentTarget.contains(e.relatedTarget);
}
