import type { SlideData } from 'photoswipe';
import PhotoSwipeLightbox, { PhotoSwipeOptions } from 'photoswipe/lightbox';

import {
  exitFullscreen,
  ifFullscreenAvailable,
  toggleFullscreen,
} from 'client/fullScreen';
import { ifPresent } from 'client/types/utils';

function getDefaultPhotoSwipeOptions(): PhotoSwipeOptions {
  return {
    bgOpacity: 0.85,
    pswpModule: () => import('photoswipe'),

    closeTitle: gettext('Close (Esc)'),
    arrowPrevTitle: gettext('Previous (arrow left)'),
    arrowNextTitle: gettext('Next (arrow right)'),
    zoomTitle: gettext('Zoom in/out'),
  };
}

/*
 * `SlideData` with Flatfox specific data. This type is compatible with `SlideData`
 * as the latter extends `Record<string, any>`. All custom data are put into a single
 * `flatfox` property to avoid breakage if Photoswipe introduces additional properties
 * they use for themselves.
 */
export type FlatfoxSlideData = SlideData & {
  flatfox?: {
    caption?: string;
  };
};

/**
 * Create a new `PhotoSwipeLightbox` with defaults and customizations we have.
 *
 * @param options Additional options to pass to the lightbox.
 */
export function createLightbox(options?: PhotoSwipeOptions): PhotoSwipeLightbox {
  const lightbox = new PhotoSwipeLightbox({
    ...getDefaultPhotoSwipeOptions(),
    ...options,
  });

  // Add captions, adapted from https://photoswipe.com/caption/
  lightbox.on('uiRegister', () => {
    const ui = lightbox.pswp?.ui;
    if (!ui) {
      // this probably shouldn't happen
      return;
    }

    ui.registerElement({
      name: 'flatfox-caption',
      className: 'pswp__flatfox-caption',
      order: 9,
      isButton: false,
      appendTo: 'root',
      html: '',
      onInit: (el, pswp) => {
        pswp.on('change', () => {
          const captionHtml = ifPresent(
            pswp.currSlide?.data,
            (slideData: FlatfoxSlideData) => {
              if (slideData.flatfox?.caption) {
                return slideData.flatfox.caption;
              }

              const currSlideElement = slideData.element;

              // Move up a level to find the caption. We expect that the thumbnail is inside some kind of
              // container and the caption element is a sibling of it.
              const hiddenCaptionElement = currSlideElement?.parentElement?.querySelector(
                '.photoswipe-item__lightbox-caption'
              );

              return hiddenCaptionElement?.innerHTML?.trim();
            }
          );

          if (captionHtml) {
            el.innerHTML = `<div class="pswp__flatfox-caption__content">${captionHtml}</div>`;
          }

          el.classList.toggle('pswp__flatfox-caption--empty', !captionHtml);
        });
      },
    });

    ifFullscreenAvailable(() => {
      ui.registerElement({
        name: 'flatfox-fullscreen',
        className: 'pswp__button--flatfox-fullscreen flatfox-icon icon-fullscreen',
        order: 9,
        isButton: true,
        html: '',
        title: gettext('Toggle fullscreen'),
        async onClick(event, el, pswp) {
          if (!pswp.element) {
            return;
          }

          const isInFullscreen = await toggleFullscreen(pswp.element);

          el.classList.toggle('icon-fullscreen', !isInFullscreen);
          el.classList.toggle('icon-fullscreen_exit', isInFullscreen);
        },
      });

      lightbox.pswp?.on('close', () => exitFullscreen());
    });
  });

  lightbox.init();

  return lightbox;
}

/**
 * Will collect all <figure/>s on the page, make them clickable, and display
 * a close-up on click. The <figure/> needs the following structure:
 *
 * <figure>
 *   <a
 *     href="[full_url]"
 *     data-pswp-width="[full_image_width]"
 *     data-pswp-height="[full_image_height]"
 *     data-pswp-srcset="[full_image_srcset (optional)]"
 *   >
 *     <img
 *       src="[thumb_url]"
 *       srcset="[thumb_srcset (optional)]"
 *       sizes="[thumb_sizes (optional)]"
 *       alt="[alt_text]"
 *     />>
 *   </a>
 *   <figcaption class="photoswipe-item__lightbox-caption"> (just the class name must match, element type doesn't matter)
 *     [caption]
 *   </figcaption
 * </figure>
 */
export default function photoswipe() {
  const lightbox = createLightbox({
    gallery: document.documentElement,
    children: 'figure > a',
  });
  lightbox.init();
}

// Also export to be run by bootloader
window.flatfox.photoswipe = { setup: photoswipe };
