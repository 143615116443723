import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

function setupPublic(
  node: ReactDOM.Container,
  props: ComponentProps<typeof SearchProfileApp>
) {
  const SearchProfileApp = createLazyComponent(() => import('./SearchProfileApp'));
  attachRoot(<SearchProfileApp {...props} />, node);
}

function setup(node: ReactDOM.Container) {
  const CrmApp = createLazyComponent(() => import('./CrmApp'));
  attachRoot(<CrmApp />, node);
}

window.flatfox.crm = {
  setup,
  setupPublic,
};
