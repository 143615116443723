import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

// Be careful what imports you add. They will be in the main chunk.

/**
 * The idea is that the {children} parameter is a React.lazy object. Your
 * child should be wrapped in a <FlatfoxApp />.
 */
export function attachRoot(children: React.ReactElement, node: ReactDOM.Container) {
  if (!node) {
    throw new Error(
      `Die Applikation ist nicht richtig konfiguriert. ` +
        `Wir sind informiert und kümmern uns darum.`
    );
  }

  // We load locales lazyly, because moment.js is huge (can be dropped once we
  // find the time). This can race agaist the first render iteration of the
  // {children}, but that does not matter because react re-renders often. We
  // prefer misformatted dates to slow loading times.
  import('flatfox_common/ui/locale').then(({ configureLocale }) => configureLocale());

  const spinner = <div />;
  ReactDOM.render(<Suspense fallback={spinner}>{children}</Suspense>, node);
}
