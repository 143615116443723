import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

function renderLogin(node: ReactDOM.Container, props: ComponentProps<typeof LoginApp>) {
  const LoginApp = createLazyComponent(() => import('./LoginApp'));

  // Django will escape our next string, WHICH IS OK, as it's provided
  // by the user through the URL, and we don't want <, > etc. in it.
  // HOWEVER, we do want & in it, not &amp;, so bring this back.
  const decodedNext = props.next ? props.next.replace(/&amp;/g, '&') : props.next;

  attachRoot(
    <LoginApp
      email={props.email}
      firstName={props.firstName}
      lastName={props.lastName}
      phoneNumber={props.phoneNumber}
      next={decodedNext}
    />,
    node
  );
}

window.flatfox.renderLogin = renderLogin;
