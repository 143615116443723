import React, { ComponentProps } from 'react';
import ReactDOM from 'react-dom';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

function setup(node: ReactDOM.Container, props: ComponentProps<typeof DashboardApp>) {
  const DashboardApp = createLazyComponent(() => import('./DashboardApp'));
  attachRoot(<DashboardApp {...props} />, node);
}

window.flatfox.dashboardApp = { setup };
