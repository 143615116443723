import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

function Container({ children, className, small, textAlign, ...rest }) {
  const finalClassName = classNames('fui-container', className, {
    'fui-container--small': small === true,
    'fui-container--small-very': small === 'very',
    'fui-container--text-align-left': textAlign === 'left',
    'fui-container--text-align-center': textAlign === 'center',
    'fui-container--text-align-right': textAlign === 'right',
  });
  return (
    <div className={finalClassName} {...rest}>
      {children}
    </div>
  );
}
export default Container;
Container.propTypes = {
  children: PropTypes.node,
  small: PropTypes.oneOf([true, false, 'very']),
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  className: PropTypes.string,
};
