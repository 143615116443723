import queryString from 'query-string';
import React, { ReactElement, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import * as url from 'client/utils/url';

import { navigateTo } from 'flatfox_common/ui/utils';
import { isAndroidWebView, isIosWebView } from 'flatfox_common/ui/utils/webview';

import { emitSignal } from 'flatfox_website/scripts/signals';

import { ModalComponent } from '@types';

import { redirectUnauthenticatedToLogin } from '../utils/redirectUnauthenticatedToLogin';

export type InjectedModalProps = { onRequestClose: () => void };

/**
 * TODO: Our modal implementation is somewhat problematic in terms of
 * bundling. Modal imports are webbing in the whole application. If you want
 * to improve bundle sizes and isolation, this is a good place to start.
 *
 * A helper to mount and render modals, based on the URL's query string.
 * 'modals' should be an array of modal components allowed to render.
 *
 * In order to be used with this, a modal component has define its mount point:
 * MyModal.mount = {
 *   slug: A unique identifier to be used in the URL.
 *   stringifyProps: A function converting props to an array of strings.
 *   parseProps: A function, converting that list back to props.
 * }
 *
 * Returns showModal, closeModal, modal (which is either undefined or the
 * rendered modal element).
 */
export default function useModals(modals: ModalComponent<unknown>[]) {
  const history = useHistory();
  const location = useLocation();

  // DevEx: quickly check if the modals have defined their mount point
  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      return;
    }
    modals.forEach((MyModal) => {
      if (!MyModal.mount) {
        console.warn(`${MyModal.name} doesn't define mount`); // eslint-disable-line no-console
      }
    });
  }, [modals]);

  // Will set the 'modal' query parameter, which should trigger showing the
  // modal. Note that we'll need to pass in the name of the actual component, hence
  // having something like a Dependency Inversion, which is cool for decoupling.
  const showModal = React.useCallback(
    (slug: string, modalProps: Record<string, string>, shouldOpenInNewTab = false) => {
      const MyModal = modals.find(({ mount }) => mount.slug === slug);
      if (!MyModal) {
        console.error(`${slug} is unknown`); // eslint-disable-line no-console
        return;
      }

      const { mount } = MyModal;

      const args = mount.stringifyProps(modalProps);
      const query = queryString.parse(history.location.search);
      const newQuery = {
        ...query,
        modal: `${mount.slug},${args.join(',')}`,
      };
      // In some cases, in particular when interacting with a mobile app through
      // a webview, we want to open a modal in a new tab instead of the current one.
      // For this, we need to know the modal's URL. This is constructed above as part
      // of `showModal`, which is why the responsibility of opening the new window
      // also lies within `showModal`, as we don't want to expose the implementation
      // detail of how modal URLs work.
      if (shouldOpenInNewTab) {
        navigateTo(url.appendParameters(window.location.pathname, newQuery), {
          shouldOpenInNewTab: true,
        });
      } else {
        history.push({
          pathname: history.location.pathname,
          search: queryString.stringify(newQuery),
        });
      }
    },
    [history, modals]
  );

  // This will just remove the 'modal' query parameter,
  // unless we're in a mobile webview, in which case we signal
  // to the native app to go back to the previous page (or native screen)
  // instead of pushing a new item onto the history stack.
  const closeModal = React.useCallback(() => {
    // LEGACY (2023-10-19): Android supports the 'goBack' signal since version 3.16.0,
    // but due to a bug that was only fixed in 3.24.0, we cannot use it here because
    // it would lead to an infinite loop. These checks can be removed once we drop
    // support for these mobile app versions.
    if (isAndroidWebView('>=3.24.0') || isIosWebView('>=3.16.0')) {
      emitSignal('goBack');
      return;
    }

    const query = queryString.parse(history.location.search);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { modal: _, ...newQuery } = query;
    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify(newQuery),
    });
  }, [history]);

  const activeModal = useMemo<ReactElement | null>(() => {
    // A modal is encoded in a single query parameter, like:
    //  <modalName>,<arg1>,<arg2>,...
    // We first extract modalName and see if we recognize the component. Then,
    // parse args and render the component.
    const query = queryString.parse(location.search);

    if (!query.modal) {
      return null;
    }

    if (typeof query.modal !== 'string') {
      // eslint-disable-next-line no-console
      console.error('Invalid type for `modal` query parameter: ', query.modal);
      return null;
    }

    const [slug, ...args] = query.modal.split(',');
    const MyModal = modals.find(({ mount }) => mount.slug === slug);
    if (!MyModal) {
      // DevEx:
      console.error(`${slug} is not in 'modals'`); // eslint-disable-line no-console
      return null;
    }

    if (MyModal.mount.requiresAuthentication) {
      redirectUnauthenticatedToLogin();
    }

    const modalProps = MyModal.mount.parseProps(args);
    return <MyModal {...modalProps} onRequestClose={closeModal} />;
  }, [closeModal, location.search, modals]);

  return [showModal, closeModal, activeModal] as const;
}
