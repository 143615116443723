import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

function setupWidget(
  node: ReactDOM.Container,
  props: ComponentProps<typeof MovingServicesWizard>
) {
  const FlatfoxApp = createLazyComponent(
    () => import('flatfox_common/ui/components/FlatfoxApp')
  );
  const MovingServicesWizard = createLazyComponent(
    () => import('./components/MovingServicesWizard')
  );

  attachRoot(
    <FlatfoxApp name='MovingServicesWizard'>
      <MovingServicesWizard {...props} />
    </FlatfoxApp>,
    node
  );
}

window.flatfox.moving_services = { setupWidget };
