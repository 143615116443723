import React from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

function setup(node: ReactDOM.Container) {
  const OrderCreditCheckApp = createLazyComponent(
    () => import('./components/OrderCreditCheckApp')
  );
  attachRoot(<OrderCreditCheckApp />, node);
}

window.flatfox.credit_check = { setup };
