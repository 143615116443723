/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * Flatfox API
 * For customers adding integrations with our API please read the API Reference linked below first.
 * OpenAPI spec version: 0.0.0 (v1)
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import httpWrapper from '../../../../flatfox_common/httpWrapper';
import type { PatchedProject, Project } from './v1.schemas';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
  T
>() => T extends Y ? 1 : 2
  ? A
  : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object
        ? NonReadonly<NonNullable<T[P]>>
        : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

export const projectList = (signal?: AbortSignal) =>
  httpWrapper<Project[]>({ url: `/api/v1/project/`, method: 'GET', signal });

export const getProjectListQueryKey = () => [`/api/v1/project/`] as const;

export const getProjectListQueryOptions = <
  TData = Awaited<ReturnType<typeof projectList>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof projectList>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProjectListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectList>>> = ({ signal }) =>
    projectList(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof projectList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ProjectListQueryResult = NonNullable<Awaited<ReturnType<typeof projectList>>>;
export type ProjectListQueryError = unknown;

export const useProjectList = <
  TData = Awaited<ReturnType<typeof projectList>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof projectList>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const projectCreate = (project: NonReadonly<Project>) =>
  httpWrapper<Project>({
    url: `/api/v1/project/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: project,
  });

export const getProjectCreateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectCreate>>,
    TError,
    { data: NonReadonly<Project> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectCreate>>,
  TError,
  { data: NonReadonly<Project> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectCreate>>,
    { data: NonReadonly<Project> }
  > = (props) => {
    const { data } = props ?? {};

    return projectCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProjectCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof projectCreate>>
>;
export type ProjectCreateMutationBody = NonReadonly<Project>;
export type ProjectCreateMutationError = unknown;

export const useProjectCreate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectCreate>>,
    TError,
    { data: NonReadonly<Project> },
    TContext
  >;
}) => {
  const mutationOptions = getProjectCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const projectPartialUpdate = (
  id: string,
  patchedProject: NonReadonly<PatchedProject>
) =>
  httpWrapper<Project>({
    url: `/api/v1/project/${id}/`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchedProject,
  });

export const getProjectPartialUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectPartialUpdate>>,
    TError,
    { id: string; data: NonReadonly<PatchedProject> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectPartialUpdate>>,
  TError,
  { id: string; data: NonReadonly<PatchedProject> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectPartialUpdate>>,
    { id: string; data: NonReadonly<PatchedProject> }
  > = (props) => {
    const { id, data } = props ?? {};

    return projectPartialUpdate(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProjectPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof projectPartialUpdate>>
>;
export type ProjectPartialUpdateMutationBody = NonReadonly<PatchedProject>;
export type ProjectPartialUpdateMutationError = unknown;

export const useProjectPartialUpdate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectPartialUpdate>>,
    TError,
    { id: string; data: NonReadonly<PatchedProject> },
    TContext
  >;
}) => {
  const mutationOptions = getProjectPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const projectDestroy = (id: string) =>
  httpWrapper<void>({ url: `/api/v1/project/${id}/`, method: 'DELETE' });

export const getProjectDestroyMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectDestroy>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectDestroy>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectDestroy>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return projectDestroy(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProjectDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof projectDestroy>>
>;

export type ProjectDestroyMutationError = unknown;

export const useProjectDestroy = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectDestroy>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = getProjectDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
