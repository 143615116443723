import { hasClass, removeClass, toggleClass } from 'flatfox_common/ui/utils/dom';

const VISIBILITY_CLASS = 'fui-dropdown-menu--visible';

/**
 * Sets up all elements with the data-fui-dropdown attribute.
 *
 * HTML ex.:
 *  <div class="fui-dropdown">
 *    <button type="button" id="trigger" data-fui-dropdown>
 *      Trigger me!
 *    </button>
 *    <div class="fui-dropdown-menu" aria-labelledby="trigger">
 *      <a href="google.ch">Go to goole</a>
 *    </div>
 *  </div>
 */
export default function setupDropdowns(subdocument) {
  const dropdowns = (subdocument || document).querySelectorAll('[data-fui-dropdown]');
  for (let i = 0; i < dropdowns.length; i += 1) {
    const trigger = dropdowns[i];
    const triggerId = trigger.getAttribute('id');
    if (!triggerId) {
      console.error('data-fui-dropdown must have an ID');
      return;
    }
    const content = document.querySelector(`[aria-labelledby=${triggerId}]`);
    if (!content) {
      return;
    }
    setupDropdown(trigger, content);
  }
}

function setupDropdown(triggerNode, contentNode) {
  // Toggle dropdown, and close them too when outside clicks occur
  function handleClick(e) {
    if (triggerNode.contains(e.target)) {
      toggleClass(contentNode, VISIBILITY_CLASS);
      return;
    }
    const isVisible = hasClass(contentNode, VISIBILITY_CLASS);
    if (!isVisible) {
      return;
    }

    if (!contentNode.contains(e.target)) {
      removeClass(contentNode, VISIBILITY_CLASS);
    }
  }
  window.addEventListener('click', handleClick, true);
}
