type DjangoI18nGlobals = {
  django?: {
    jsi18n_initialized?: boolean;
  };
};

// How long to wait before giving up.
const I18N_LOAD_TIMEOUT_MS = 5000;

const global = window as DjangoI18nGlobals;
if (!global.django) {
  global.django = {};
}

const i18nReadyPromise = global.django.jsi18n_initialized
  ? // i18n is initialized already, no action needed.
    Promise.resolve()
  : // i18n is not initialized yet, wait for it…
    new Promise<void>((resolve, reject) => {
      const timeoutErrorHandler = setTimeout(() => {
        if (process.env.NODE_ENV !== 'production') {
          // If we run into this error,
          // Django's jsi18n has likely changed in a way that our little hack here does not work anymore.
          // This should not start happening unless we upgraded Django.
          reject(new Error('FOX-88781: Timeout waiting for jsi18n'));
          return;
        }

        // Try to proceed anyway, it could be just Django changing stuff that broke our hack.
        // We reject the promise in development mode,
        // so that should raise the attention of the person upgrading Django.
        resolve();
      }, I18N_LOAD_TIMEOUT_MS);

      // jsi18n sets `django.jsi18n_initialized` to `true` when it's loaded,
      // intercept the assignment with a setter to resolve the promise.
      Object.defineProperty(global.django, 'jsi18n_initialized', {
        configurable: true,
        enumerable: true,
        get() {
          // This getter will be removed once the flag has been flipped.
          return false;
        },
        set(newValue) {
          if (newValue) {
            // The flag is set to `true`, undo our magic and convert the property back to a mundane one.
            Object.defineProperty(global.django, 'jsi18n_initialized', {
              configurable: true,
              enumerable: true,
              value: true,
              writable: true,
            });

            // Now we're done, and we clean up can resolve the promise.
            clearTimeout(timeoutErrorHandler);
            resolve();
          }
        },
      });
    });

/**
 * Returns a promise that resolves when Django i18n functions are available.
 */
export function jsi18nReady(): Promise<void> {
  return i18nReadyPromise;
}
