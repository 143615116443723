import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

import type InventoryManagementApp from './InventoryManagementApp';

function managementApp(
  node: ReactDOM.Container,
  props: ComponentProps<typeof InventoryManagementApp>
) {
  const InventoryManagement = createLazyComponent(
    () => import('./InventoryManagementApp')
  );
  attachRoot(<InventoryManagement {...props} />, node);
}

window.flatfox.inventory = { managementApp };
