import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

function setup(
  node: ReactDOM.Container,
  props: ComponentProps<typeof SecurityDepositRequestApp>
) {
  const SecurityDepositRequestApp = createLazyComponent(
    () => import('./SecurityDepositRequestApp')
  );
  attachRoot(<SecurityDepositRequestApp {...props} />, node);
}

window.flatfox.securityDepositRequest = { setup };
