import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

export default function render(
  node: ReactDOM.Container,
  props: ComponentProps<typeof import('./DirectSubmitApp').default>
) {
  const DirectSubmitApp = createLazyComponent(() => import('./DirectSubmitApp'));
  attachRoot(<DirectSubmitApp {...props} />, node);
}

window.flatfox.apply = { render };
