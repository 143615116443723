import setupApp from 'flatfox_website/scripts/app';
import setupConfirm from 'flatfox_website/styles/widgets/confirm';
import setupDropdowns from 'flatfox_website/styles/widgets/dropdown';
import setupLockOnSubmit from 'flatfox_website/styles/widgets/lock-on-submit';
import setupCsrfMiddlewareToken from 'flatfox_website/views/base/form_csrf';
import setupGTCNag from 'flatfox_website/views/base/gtc_nag';
import setupMessages from 'flatfox_website/views/base/messages';

window.flatfox.base = function setupBase() {
  if (window.flatfoxConfig.isWebviewRequest) {
    setupApp();
  }

  setupMessages();
  setupGTCNag();

  setupConfirm();
  setupDropdowns();
  setupLockOnSubmit();
  setupCsrfMiddlewareToken();
};
