import * as Sentry from '@sentry/browser';
import React, { ReactNode } from 'react';

import Alert from 'flatfox_common/ui/containers/Alert';
import Container from 'flatfox_common/ui/containers/Container';
import { genericError } from 'flatfox_common/ui/text/error';

type ErrorBoundaryProps = {
  context: string;
  onError?: () => void;
  children: ReactNode;
};

export default class ErrorBoundary extends React.PureComponent<ErrorBoundaryProps> {
  state = { error: null };

  componentDidMount() {
    disableEmotionErrors();
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    const { context, onError } = this.props;

    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      scope.setTag('ErrorBoundary', context);
      scope.setLevel('fatal'); // This is really bad.
      Sentry.captureException(error);
    });

    if (onError) {
      onError();
    }
    const msg = `Boundary error (${context}): ${error}`;
    console.error(msg, error, errorInfo); // eslint-disable-line no-console
  }

  render() {
    if (this.state.error) {
      return (
        <Container>
          <Alert kind='error'>{genericError(true)}</Alert>
        </Container>
      );
    }
    return this.props.children;
  }
}

function disableEmotionErrors() {
  // horribly annoying error from emotion https://github.com/trendmicro-frontend/tonic-ui/issues/158
  const log = console.error.bind(console);
  console.error = (...args) => {
    if (
      typeof args[0] === 'string' &&
      args[0].includes('The pseudo class') &&
      args[0].includes(
        'is potentially unsafe when doing server-side rendering. Try changing it to'
      )
    ) {
      return;
    }
    log(...args);
  };
}
