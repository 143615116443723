import React, { ComponentProps } from 'react';

import { FlatfoxBareApp } from 'flatfox_common/ui/components/FlatfoxApp';
import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

export default function setup(
  node: ReactDOM.Container,
  props: ComponentProps<typeof import('./ProjectTableApp').default>
) {
  const ProjectTableApp = createLazyComponent(() => import('./ProjectTableApp'));
  attachRoot(
    <FlatfoxBareApp name='project-table'>
      <ProjectTableApp {...props} />
    </FlatfoxBareApp>,
    node
  );
}
