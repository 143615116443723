// TODO: bug in our ESLint config
//  see https://github.com/typescript-eslint/tslint-to-eslint-config/issues/856
// eslint-disable-next-line no-shadow
export enum Brand {
  FLATFOX = 'flatfox',
  IMMOSCOUT24 = 'immoscout24',
  HOMEGATE = 'homegate',
}

export const BuiltinLabelType = {
  CONTACT_REQUEST: 'contact-request',
  FORM_SUBMITTED: 'form-submitted',
  CHECKLIST_CREATED: 'checklist-created',
  CHECKLIST_FINISHED: 'checklist-finished',
  APPLICATION_PUSHED: 'application-pushed',
  CONTRACT_SIGNING: 'contract-signing',
  CONTRACT_SIGNING_COMPLETED: 'contract-signing-completed',
  APPLICATION_CHOSEN: 'application-chosen',
  VIEWING_APPOINTMENT: 'viewing-appointment',
  VIEWING_CHECKIN: 'viewing-checkin',
  VIEWING_SURVEY: 'viewing-survey',
  LISTING_TASK: 'listing-task',
  LISTING_RESERVED: 'listing-reserved',
  EXPORT: 'export',
  EXPORT_SERVICE: 'export-service',
  PROMOTION: 'promotion',
  IN_LIABILITY: 'in-liability',
  VACANT: 'vacant',
  CRM_MATCH: 'crm-match',
  SECURITY_DEPOSIT_PENDING: 'security-deposit-pending',
  SECURITY_DEPOSIT: 'security-deposit',
} as const;

export const LabelScope = {
  LISTING: 'listing',
  APPLICATION: 'application',
};

export const SortDirection = Object.freeze({ NOT_SET: 0, ASC: 1, DESC: 2 });
export type SortDirection = typeof SortDirection[keyof typeof SortDirection];

export const ListingStatus = Object.freeze({
  RENTED: 0,
  RESERVED: 1,
  AVAILABLE: 2,
});

export const MovingDateType = Object.freeze({
  NOT_SET: { sort_key: 0, name: undefined },
  AGREEMENT: { sort_key: 1, name: 'agr' },
  DATE: { sort_key: 2, name: 'dat' },
  IMMEDIATELY: { sort_key: 3, name: 'imm' },
  nameOf(name) {
    switch (name) {
      case this.IMMEDIATELY.name:
        return this.IMMEDIATELY;
      case this.AGREEMENT.name:
        return this.AGREEMENT;
      case this.DATE.name:
        return this.DATE;
      default:
        return this.NOT_SET;
    }
  },
  fromListing(listing) {
    const type = this.nameOf(listing.moving_date_type);
    if (type === this.DATE) {
      if (listing.moving_date === null || listing.moving_date === '') {
        // date specific with no valid date set is effectively
        // not-set
        return this.NOT_SET;
      }
      const movingDate = new Date(listing.moving_date);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (movingDate < today) {
        // moving date in the past are treated as
        // an agreement
        return this.AGREEMENT;
      }
    }
    return type;
  },
});

export const VatType = Object.freeze({
  INCL: 'incl',
  EXCL: 'excl',
});
