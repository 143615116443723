/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * Flatfox API
 * For customers adding integrations with our API please read the API Reference linked below first.
 * OpenAPI spec version: 0.0.0 (v1)
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import httpWrapper from '../../../../flatfox_common/httpWrapper';
import type {
  Config,
  ConfigRetrieveParams,
  FormConfig,
  PatchedFormConfig,
} from './v1.schemas';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
  T
>() => T extends Y ? 1 : 2
  ? A
  : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object
        ? NonReadonly<NonNullable<T[P]>>
        : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

export const configRetrieve = (
  configKey: string,
  params?: ConfigRetrieveParams,
  signal?: AbortSignal
) =>
  httpWrapper<Config>({
    url: `/api/v1/config/${configKey}/`,
    method: 'GET',
    params,
    signal,
  });

export const getConfigRetrieveQueryKey = (
  configKey: string,
  params?: ConfigRetrieveParams
) => [`/api/v1/config/${configKey}/`, ...(params ? [params] : [])] as const;

export const getConfigRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof configRetrieve>>,
  TError = unknown
>(
  configKey: string,
  params?: ConfigRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof configRetrieve>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getConfigRetrieveQueryKey(configKey, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof configRetrieve>>> = ({
    signal,
  }) => configRetrieve(configKey, params, signal);

  return { queryKey, queryFn, enabled: !!configKey, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof configRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ConfigRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof configRetrieve>>
>;
export type ConfigRetrieveQueryError = unknown;

export const useConfigRetrieve = <
  TData = Awaited<ReturnType<typeof configRetrieve>>,
  TError = unknown
>(
  configKey: string,
  params?: ConfigRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof configRetrieve>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getConfigRetrieveQueryOptions(configKey, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const configUpdate = (configKey: string, config: NonReadonly<Config>) =>
  httpWrapper<Config>({
    url: `/api/v1/config/${configKey}/`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: config,
  });

export const getConfigUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof configUpdate>>,
    TError,
    { configKey: string; data: NonReadonly<Config> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof configUpdate>>,
  TError,
  { configKey: string; data: NonReadonly<Config> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof configUpdate>>,
    { configKey: string; data: NonReadonly<Config> }
  > = (props) => {
    const { configKey, data } = props ?? {};

    return configUpdate(configKey, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfigUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof configUpdate>>
>;
export type ConfigUpdateMutationBody = NonReadonly<Config>;
export type ConfigUpdateMutationError = unknown;

export const useConfigUpdate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof configUpdate>>,
    TError,
    { configKey: string; data: NonReadonly<Config> },
    TContext
  >;
}) => {
  const mutationOptions = getConfigUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Allows the view to prefetch data, given the queryset that will be displayed,
and add it to the actual instances in one batch. For speed optimization.
 */
export const configFormList = (signal?: AbortSignal) =>
  httpWrapper<FormConfig[]>({ url: `/api/v1/config/form/`, method: 'GET', signal });

export const getConfigFormListQueryKey = () => [`/api/v1/config/form/`] as const;

export const getConfigFormListQueryOptions = <
  TData = Awaited<ReturnType<typeof configFormList>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof configFormList>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getConfigFormListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof configFormList>>> = ({
    signal,
  }) => configFormList(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof configFormList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ConfigFormListQueryResult = NonNullable<
  Awaited<ReturnType<typeof configFormList>>
>;
export type ConfigFormListQueryError = unknown;

export const useConfigFormList = <
  TData = Awaited<ReturnType<typeof configFormList>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof configFormList>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getConfigFormListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Allows the view to prefetch data, given the queryset that will be displayed,
and add it to the actual instances in one batch. For speed optimization.
 */
export const configFormCreate = (formConfig: NonReadonly<FormConfig>) =>
  httpWrapper<FormConfig>({
    url: `/api/v1/config/form/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: formConfig,
  });

export const getConfigFormCreateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof configFormCreate>>,
    TError,
    { data: NonReadonly<FormConfig> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof configFormCreate>>,
  TError,
  { data: NonReadonly<FormConfig> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof configFormCreate>>,
    { data: NonReadonly<FormConfig> }
  > = (props) => {
    const { data } = props ?? {};

    return configFormCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfigFormCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof configFormCreate>>
>;
export type ConfigFormCreateMutationBody = NonReadonly<FormConfig>;
export type ConfigFormCreateMutationError = unknown;

export const useConfigFormCreate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof configFormCreate>>,
    TError,
    { data: NonReadonly<FormConfig> },
    TContext
  >;
}) => {
  const mutationOptions = getConfigFormCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Allows the view to prefetch data, given the queryset that will be displayed,
and add it to the actual instances in one batch. For speed optimization.
 */
export const configFormPartialUpdate = (
  id: number,
  patchedFormConfig: NonReadonly<PatchedFormConfig>
) =>
  httpWrapper<FormConfig>({
    url: `/api/v1/config/form/${id}/`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchedFormConfig,
  });

export const getConfigFormPartialUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof configFormPartialUpdate>>,
    TError,
    { id: number; data: NonReadonly<PatchedFormConfig> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof configFormPartialUpdate>>,
  TError,
  { id: number; data: NonReadonly<PatchedFormConfig> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof configFormPartialUpdate>>,
    { id: number; data: NonReadonly<PatchedFormConfig> }
  > = (props) => {
    const { id, data } = props ?? {};

    return configFormPartialUpdate(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfigFormPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof configFormPartialUpdate>>
>;
export type ConfigFormPartialUpdateMutationBody = NonReadonly<PatchedFormConfig>;
export type ConfigFormPartialUpdateMutationError = unknown;

export const useConfigFormPartialUpdate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof configFormPartialUpdate>>,
    TError,
    { id: number; data: NonReadonly<PatchedFormConfig> },
    TContext
  >;
}) => {
  const mutationOptions = getConfigFormPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Allows the view to prefetch data, given the queryset that will be displayed,
and add it to the actual instances in one batch. For speed optimization.
 */
export const configFormDestroy = (id: number) =>
  httpWrapper<void>({ url: `/api/v1/config/form/${id}/`, method: 'DELETE' });

export const getConfigFormDestroyMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof configFormDestroy>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof configFormDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof configFormDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return configFormDestroy(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfigFormDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof configFormDestroy>>
>;

export type ConfigFormDestroyMutationError = unknown;

export const useConfigFormDestroy = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof configFormDestroy>>,
    TError,
    { id: number },
    TContext
  >;
}) => {
  const mutationOptions = getConfigFormDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
