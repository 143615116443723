import { QueryClient, UseQueryOptions, useQuery } from '@tanstack/react-query';
import groupBy from 'lodash/groupBy';

import * as apiUrls from 'flatfox_common/api/apiUrls';
import * as config from 'flatfox_common/config';
import * as http from 'flatfox_common/http';

import {
  type GroupedUnreadState,
  Role,
  type UnreadStateGroupbyListParams,
  useUnreadStateGroupbyList,
} from './v1';

type GroupByField = Exclude<keyof GroupedUnreadState, 'count' | 'created'>;
export type GroupedUnreadStateForGroups<Groups extends GroupByField> = Required<
  Pick<GroupedUnreadState, Groups | 'count' | 'created'>
>;

export function useGroupedUnreadCounts<
  GroupByFields extends readonly [...GroupByField[]]
>(
  groupByFields: GroupByFields,
  params?: UnreadStateGroupbyListParams,
  options?: {
    query?: UseQueryOptions<
      GroupedUnreadState[],
      unknown,
      GroupedUnreadStateForGroups<GroupByFields[number]>[]
    >;
  }
) {
  return useUnreadStateGroupbyList<GroupedUnreadStateForGroups<GroupByFields[number]>[]>(
    groupByFields.join('/'),
    params,
    options
  );
}

const UnreadCountQueryKey = apiUrls.messenger.unreadStates;

/**
 * Invalidate cached unread counts.
 * Should be called whenever an action has been taken that potentially impacts the results of the queries here.
 */
export function invalidateUnreadCounts(queryClient: QueryClient): Promise<void> {
  return queryClient.invalidateQueries([UnreadCountQueryKey]);
}

export function useUnreadsByListing(role, options) {
  const urlTemplate = apiUrls.messenger.unreadStates;
  const urlAction = 'groupby/listing/internal/';
  const query = { role, participant: config.getUserPk() };
  const url = apiUrls.list(urlTemplate + urlAction, query);
  return useQuery(
    [UnreadCountQueryKey],
    () => http.get(url).then((unreads) => groupBy(unreads, 'listing')),
    options
  );
}

export function useUnreadsByTicket(role, options) {
  const urlTemplate = apiUrls.messenger.unreadStates;
  const urlAction = 'groupby/ticket/internal/';
  const query = { role, participant: config.getUserPk() };
  const url = apiUrls.list(urlTemplate + urlAction, query);
  return useQuery(
    [UnreadCountQueryKey],
    () => http.get(url).then((unreads) => groupBy(unreads, 'ticket')),
    options
  );
}

export function useUnreadsByLeaseTermination(role, options) {
  const urlTemplate = apiUrls.messenger.unreadStates;
  const urlAction = 'groupby/lease_termination/internal/';
  const query = { role, participant: config.getUserPk() };
  const url = apiUrls.list(urlTemplate + urlAction, query);
  return useQuery(
    [UnreadCountQueryKey],
    () => http.get(url).then((unreads) => groupBy(unreads, 'lease_termination')),
    options
  );
}

export function useUnreadsByThreadStatus(
  role: Role,
  options: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  listingPk?: number,
  ticketPk?: number,
  leaseTerminationPk?: number
) {
  const urlTemplate = apiUrls.messenger.unreadStates;
  const urlAction = 'groupby/thread_status/internal/';
  const query = {
    role,
    participant: config.getUserPk(),
    listing: listingPk,
    ticket: ticketPk,
    lease_termination: leaseTerminationPk,
  };
  const url = apiUrls.list(urlTemplate + urlAction, query);
  return useQuery(
    [UnreadCountQueryKey, query],
    () => http.get(url).then((unreads) => groupBy(unreads, 'thread_status')),
    options
  );
}
