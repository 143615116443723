import React from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

function setupExportersEditor(node: ReactDOM.Container) {
  const ExportersApp = createLazyComponent(() => import('./ExportersApp'));
  attachRoot(<ExportersApp />, node);
}
window.flatfox.transfer = { setupExportersEditor };
