import * as http from 'flatfox_common/http';

import { urlWithQuery } from './ui/utils';

/**
 * A wrapper that adapts our {@link http} client
 * to the react-query hooks generated by Orval.
 * Only code generated by Orval should use this.
 * Use {@link http} if you need an HTTP client.
 */
export default async function httpWrapper<T>({
  url,
  method,
  params,
  data,
}: {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  params?: object;
  data?: unknown;
  responseType?: string;

  // While it's not documented at <https://orval.dev/guides/custom-client>, the generated
  // react-query hooks use that to support query cancellation
  signal?: AbortSignal;
}): Promise<T> {
  const urlWithQueryParams = urlWithQuery(url, params);
  switch (method) {
    case 'GET':
      return http.get(urlWithQueryParams);
    case 'POST':
      return http.post(urlWithQueryParams, data);
    case 'PUT':
      return http.put(urlWithQueryParams, data);
    case 'DELETE':
      return http.destroy(urlWithQueryParams);
    case 'PATCH':
      return http.patch(urlWithQueryParams, data);
    default:
      throw new Error(
        `Please don't invent new HTTP methods or at least be more creative than '${method}'`
      );
  }
}
