/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * Flatfox API
 * For customers adding integrations with our API please read the API Reference linked below first.
 * OpenAPI spec version: 0.0.0 (v1)
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import httpWrapper from '../../../../flatfox_common/httpWrapper';
import type { Files } from './v1.schemas';

export const filesList = (signal?: AbortSignal) =>
  httpWrapper<Files[]>({ url: `/api/v1/files/`, method: 'GET', signal });

export const getFilesListQueryKey = () => [`/api/v1/files/`] as const;

export const getFilesListQueryOptions = <
  TData = Awaited<ReturnType<typeof filesList>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof filesList>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFilesListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof filesList>>> = ({ signal }) =>
    filesList(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof filesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FilesListQueryResult = NonNullable<Awaited<ReturnType<typeof filesList>>>;
export type FilesListQueryError = unknown;

export const useFilesList = <
  TData = Awaited<ReturnType<typeof filesList>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof filesList>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFilesListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
