/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * Flatfox API
 * For customers adding integrations with our API please read the API Reference linked below first.
 * OpenAPI spec version: 0.0.0 (v1)
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import httpWrapper from '../../../../flatfox_common/httpWrapper';
import type { PaginatedPropertyList, Property, PropertyListParams } from './v1.schemas';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
  T
>() => T extends Y ? 1 : 2
  ? A
  : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object
        ? NonReadonly<NonNullable<T[P]>>
        : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

export const propertyList = (params?: PropertyListParams, signal?: AbortSignal) =>
  httpWrapper<PaginatedPropertyList>({
    url: `/api/v1/property/`,
    method: 'GET',
    params,
    signal,
  });

export const getPropertyListQueryKey = (params?: PropertyListParams) =>
  [`/api/v1/property/`, ...(params ? [params] : [])] as const;

export const getPropertyListQueryOptions = <
  TData = Awaited<ReturnType<typeof propertyList>>,
  TError = unknown
>(
  params?: PropertyListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof propertyList>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPropertyListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof propertyList>>> = ({ signal }) =>
    propertyList(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof propertyList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PropertyListQueryResult = NonNullable<
  Awaited<ReturnType<typeof propertyList>>
>;
export type PropertyListQueryError = unknown;

export const usePropertyList = <
  TData = Awaited<ReturnType<typeof propertyList>>,
  TError = unknown
>(
  params?: PropertyListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof propertyList>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPropertyListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Creating/updating properties being imported from a CSV file uploaded by admin
 */
export const propertySyncCreate = (property: NonReadonly<Property[]>) =>
  httpWrapper<Property>({
    url: `/api/v1/property/sync/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: property,
  });

export const getPropertySyncCreateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof propertySyncCreate>>,
    TError,
    { data: NonReadonly<Property[]> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof propertySyncCreate>>,
  TError,
  { data: NonReadonly<Property[]> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof propertySyncCreate>>,
    { data: NonReadonly<Property[]> }
  > = (props) => {
    const { data } = props ?? {};

    return propertySyncCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PropertySyncCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof propertySyncCreate>>
>;
export type PropertySyncCreateMutationBody = NonReadonly<Property[]>;
export type PropertySyncCreateMutationError = unknown;

export const usePropertySyncCreate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof propertySyncCreate>>,
    TError,
    { data: NonReadonly<Property[]> },
    TContext
  >;
}) => {
  const mutationOptions = getPropertySyncCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
