import moment from 'moment-timezone';

import { joinIfSet } from 'flatfox_common/ui/utils';

export function formatAddress<
  T extends {
    street?: string | null;
    city?: string | null;
    zipcode?: string | number | null;
  }
>(value: T | null, multiline = false): string | null {
  const joinChar = multiline ? '\n' : ', ';
  return (
    joinIfSet(
      [value?.street, joinIfSet([value?.zipcode?.toString(), value?.city], ' ')],
      joinChar
    ) || null
  );
}

export function streetGroupingKey(
  street: string | null | undefined
): string | null | undefined {
  street = street
    ?.toLowerCase()
    .replace('strasse', 'str')
    .replace(/\s|[^a-z0-9]|/g, '');
  return street;
}

export function tenantAddressGroupingKey<
  T extends { street?: string | null; city?: string | null; zipcode?: string | null }
>(
  value: Pick<T, 'street' | 'city' | 'zipcode'> | null,
  multiline = false
): string | null {
  const joinChar = multiline ? '\n' : ', ';
  const street = streetGroupingKey(value?.street);
  return (
    joinIfSet([street, joinIfSet([value?.zipcode, value?.city], ' ')], joinChar) || null
  );
}

/**
 * Calls {@link formatFullName} and truncates like so:
 * Needs at least one value.
 *
 * One value: 'Fritz First'
 * Two values: 'Fritz First & Sandra Second'
 * More: 'Fritz First and X other persons'
 */
export function formatFullNames<
  T extends { first_name?: string | null; last_name?: string | null }
>(values: T[]): string {
  switch (values.length) {
    case 0:
      throw new Error('Need at least one person');
    case 1:
      return formatFullName(values[0]);
    case 2:
      return `${formatFullName(values[0])} & ${formatFullName(values[1])}`;
    default:
      return `${interpolate(gettext('%s and %s other persons'), [
        formatFullName(values[0]),
        `${values.length - 1}`,
      ])}`;
  }
}

/**
 * This needs expanded user on ticket. If requester's full_name is not available,
 * we fall back to requester_first_name and requester_last_name
 */
export function formatFullName<
  T extends { first_name?: string | null; last_name?: string | null }
>(value: T): string {
  return joinIfSet([value.first_name, value.last_name], ' ');
}

export const formatFromNow = (date: string | Date) => `${moment(date).fromNow()}`;

export function formatFullDateTime(dateTime: string | Date): string {
  return moment(dateTime).format('lll');
}

export function formatShortFullDate(dateTime: string | Date): string {
  return moment(dateTime).format('L');
}

export function formatShortTime(dateTime: string | Date): string {
  return moment(dateTime).format('LT');
}

export function formatFullNameAndAddress<
  T extends {
    first_name?: string | null;
    last_name?: string | null;
    street?: string | null;
    city?: string | null;
    zipcode?: string | null;
  }
>(value: T, multiline = false): string {
  const fullName = formatFullName(value);
  const joinChar = multiline ? '\n' : ', ';
  const address = formatAddress(value, multiline);
  return joinIfSet([fullName, address], joinChar);
}

export function formatPhoneNumberToE164(
  phoneNumber: string | undefined
): string | undefined {
  if (phoneNumber === undefined || phoneNumber === '') {
    return phoneNumber;
  }
  // remove all non-alphanumeric characters
  phoneNumber = phoneNumber.replace(/[\s/()]+/g, '');

  if (phoneNumber.startsWith('00')) {
    phoneNumber = `+${phoneNumber.substring(2)}`;
  }

  // fallback country CH and fix common errors
  const patterns = [
    { regex: /^0(\d{2}\d{7})$/, replaceWith: '+41$1' }, // 052 123 45 67
    { regex: /^\+(41)?(7[56789]\d{7})$/, replaceWith: '+41$2' }, // +79 123 45 67
    { regex: /^\+(41)?0(7[56789]\d{7})$/, replaceWith: '+41$2' }, // +079 123 45 67
  ];

  for (let i = 0; i < patterns.length; i += 1) {
    if (phoneNumber.match(patterns[i].regex)) {
      return phoneNumber.replace(patterns[i].regex, patterns[i].replaceWith);
    }
  }

  return phoneNumber;
}
