import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

function creator(
  node: ReactDOM.Container,
  props: ComponentProps<typeof LeaseTerminationCreator>
) {
  const LeaseTerminationCreator = createLazyComponent(
    () => import('./creator/LeaseTerminationCreator')
  );

  attachRoot(<LeaseTerminationCreator {...props} />, node);
}

function app(
  node: ReactDOM.Container,
  props: ComponentProps<typeof LeaseTerminationApp>
) {
  const LeaseTerminationApp = createLazyComponent(
    () => import('./app/LeaseTerminationApp')
  );
  attachRoot(<LeaseTerminationApp {...props} />, node);
}

window.flatfox.lease_termination = { creator, app };
